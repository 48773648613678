.footer {
  width: 100%;
  min-height: 447px;
  background: #000000;
}

.footerInner {
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 20px;
}

.topFoot {
  display: flex;
  padding-bottom: 20px;
}

.leftNav {
  display: flex;
  width: 44%;
}

.somosArea {
  display: flex;
  flex-direction: column;
  margin-top: 74px;
  margin-left: 6.65%;
}

.somo1 {
  margin-top: 18px;
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20.2995px;
  line-height: 30px;
  color: #ffffff;
}

.secreee {
  display: flex;
  flex-direction: column;
  margin-top: 74px;
  margin-left: 12.6%;
}

.estar1 {
  margin-top: 92px;
  display: flex;
  flex-direction: column;
}

.estar {
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20.2995px;
  line-height: 30px;
  color: #ffffff;
}

.imagLotus {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 69px;
  margin-left: 23.5%;
}

.iconArea {
  display: flex;
  margin-top: 48px;
  justify-content: center;
}

.insta {
  width: 98px;
  height: 98px;
  background: white;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
}

.insta1 {
  width: 98px;
  height: 98px;
  background: white;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 48px;
  color: #000000;
}

.instaInner {
  width: 80%;
  height: 80%;
}

.rightNav {
  margin-left: 1%;
  display: flex;
  width: 50%;
}

.comflo {
  width: 100%;
  height: 193px;
  object-fit: contain;
}

.catLotus {
  font-family: "Tinos";
  font-style: normal;
  font-weight: 700;
  font-size: 40.4457px;
  line-height: 47px;
  color: #ffffff;
  margin-top: 16px;
}

.copyRight {
  display: flex;
  justify-content: flex-end;
  margin-top: 48px;
}

.copy {
  font-family: "Hind", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13.6116px;
  line-height: 20px;
  color: #ffffff;
  margin-right: 7.7%;
}

.leftacceso {
  display: flex;
}

@media (max-width: 992px) {
  .topFoot {
    flex-direction: column;
    align-items: center;
  }

  .rightNav {
    width: 80%;
    justify-content: space-between;
  }

  .somosArea {
    margin-left: 0;
  }

  .leftNav {
    width: 80%;
    justify-content: space-between;
  }

  .imagLotus {
    margin-left: 10.5%;
  }

  .copyRight {
    justify-content: center;
  }
}

@media (max-width: 600px) {
  .leftNav {
    flex-direction: column;
    align-items: center;
  }

  .rightNav {
    flex-direction: column;
    align-items: center;
  }

  .secreee {
    margin-top: 33px;
    margin-left: 0;
  }

  .imagLotus {
    margin-left: 0;
    margin-top: 33px;
  }

  .iconArea {
    margin-top: 15px;
  }

  .insta {
    width: 60px;
    height: 60px;
    border-radius: 8px;
  }

  .insta1 {
    width: 60px;
    height: 60px;
    border-radius: 8px;
  }
}

@media (max-width: 450px) {
  .somo1 {
    font-size: 15.2995px;
    line-height: 24px;
    margin-top: 12px;
  }
}
