.faq {
    display: flex;
    padding-top: 38px;
    padding-left: 48px;
    padding-right: 48px;
    flex-direction: column;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
}


.hedaing {
    font-family: 'Asap', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24.5867px;
    line-height: 129%;
    color: #FF9F37;
}


.headingDetail {
    font-family: 'Hind';
    font-style: normal;
    font-weight: 500;
    font-size: 14.3833px;
    line-height: 129%;
    color: #040404;
    margin-top: 18px;
    max-width: 1265px;
}


.pregHeading {
    margin-top: 64px;
    font-family: 'Asap', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24.5867px;
    line-height: 129%;
    color: #FF9F37;
}

.estan {
    margin-top: 24px;
    font-family: 'Asap', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 19.0881px;
    line-height: 129%;
    color: #FF9F37;
}


.somoss {
    font-family: 'Hind';
    font-style: normal;
    font-weight: 500;
    font-size: 14.3833px;
    line-height: 129%;
    color: #040404;
    max-width: 1300px;
}


.underli {
    text-decoration: none;
}


@media (max-width:500px) {


    .faq {
        padding-left: 18px;
        padding-right: 18px;

    }


    .pregHeading{
        margin-top: 40px;
    }

}