.adrese {
    width: 100%;
    display: flex;
    margin-top: -37px;
}

.yelArea {
    width: 21.5%;
    min-height: 100vh;
    background: #FFCF10;
    display: flex;
    flex-direction: column;
    padding-top: 105px;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
}

.geading {
    font-family: 'Asap', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 27.0315px;
    line-height: 43px;
    text-align: center;
    color: #17161F;
}


.misArea {
    display: flex;
}


.personal {
    font-family: 'Hind';
    font-style: normal;
    font-weight: 400;
    font-size: 15.6995px;
    line-height: 25px;
    text-align: center;
    color: #17161F;
    margin-left: 10px;
}


.whiteKann1 {
    width: 12px;
    height: 15px;
}

.cuenta {
    display: flex;
    flex-direction: column;
}

.whiteKann2 {
    width: 21px;
    height: 33px;
}

.rightArea {
    width: 78.5%;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 50px;
}

.rightAreaInner {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 927px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 70px;
}

.cratashEad {
    font-family: 'Asap', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 33.0188px;
    line-height: 53px;
    color: #17161F;
}

.addNew {
    width: 128.37px;
    height: 31px;
    margin-top: 10px;
    background: #FFCF10;
    border-radius: 21.6205px;
    font-family: 'Asap', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 13.5128px;
    line-height: 22px;
    color: #FFFFFF;
    margin-left: auto;
    margin-right: 50px;
    border: none;
}


.topRow {
    font-family: 'Asap', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14.4869px;
    line-height: 23px;
    color: #17161F;
}

.micant {
    display: flex;
    align-items: center;
}

.whiteKann {
    width: 16px;
    height: 20px;
    margin-left: 3px;
}

.whiteKanncenter{
    width: 19px;
    height: 29px;
}

.whiteKannlast{
    width: 12px;
    height: 15px;
    margin-left: 5px;
}


.edits {
    width: 34px;
    height: 30px;
    background: #F0F0F0;
    border: 1.22149px solid #000000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.addressDetail {
    font-family: 'Hind';
    font-style: normal;
    font-weight: 400;
    font-size: 16.4624px;
    line-height: 26px;
    color: #17161F;
}


.penInne{
    width: 13px;
    height: 13px;
    fill: #009FF9;
}

.penInne > .MuiTable-root  > .MuiTableHead-root > .MuiTableRow-head {
    border: none !important;
}


@media (max-width:1050px) {

    .adrese{
        flex-direction: column;
    }

    .yelArea{
        width: 100%;
        margin-top: 30px;
        min-height: fit-content;
        padding-bottom: 50px;
        padding-top: 50px;
    }

.rightArea{
    width: 100%;
}

    
}