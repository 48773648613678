.secra {
  background: #ffcf10;
  min-height: 120px;
  display: flex;
  align-items: flex-end;
  margin-bottom: 37px;
}

.secretArea {
  width: 100%;
  /* max-width: 1360px; */
  display: flex;
  align-items: flex-end;
  padding-bottom: 12px;
  justify-content: space-around;
  margin-left: auto;
  margin-right: auto;
  align-self: flex-end;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  height: 100px;
}

.comflonav {
  /* position: absolute; */
  width: 153px;
  height: 120px;
  margin-top: -100px;
  /* left: 31px; */
}

.compra {
  position: relative;
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20.2535px;
  line-height: 23px;
  color: #17161f !important;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  text-decoration: none;
}

.compraworkShop {
  position: relative;
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20.2535px;
  line-height: 23px;
  color: #17161f !important;
  margin-left: 10px;
  margin-right: 10px;
  /* max-width: 120px; */
  text-align: center;
  text-decoration: none;
}

.lair {
  width: 160px;
  height: 86px;
  position: relative;
  margin-left: 10px;
  margin-right: 10px;
}

.comobasket {
  width: 50px;
  height: 50px;
}

.como {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.venidos {
  background: #ffcf10;
  width: 100%;
  min-height: 638px;
}

.centervanidos {
  display: flex;
  width: 100%;
  max-width: 1440px;
  align-items: center;
  height: 100%;
  justify-content: space-around;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.computerImageArea {
  width: 33.26%;
  height: 361px;
  position: relative;
}

.comput {
  width: 100%;
  height: 100%;
}

.comflo {
  width: 30%;
  height: 117px;
  position: absolute;
  left: 13.2%;
  top: 115px;
}

.comprara {
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 41.4109px;
  line-height: 48px;
  color: #17161f;
  position: absolute;
  right: 15.9%;
  top: 174px;
}

.catRea {
  width: 45.4%;
  display: flex;
  flex-direction: column;
  padding-top: 62px;
  align-items: center;
  justify-content: space-between;
}

.bien {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 70.1313px;
  line-height: 105px;
  color: #17161f;
}

.nuestra {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 30.0053px;
  line-height: 41px;
  text-align: center;
  color: #17161f;
  max-width: 654px;
}

@media (max-width: 1120px) {
  .computerImageArea {
    width: 40.26%;
  }
}

@media (max-width: 992px) {
  .comflonav {
    display: none;
  }
}

@media (max-width: 540px) {
  .comobasket {
    display: none;
  }
}
@media (max-width: 767px) {
  .secretArea {
    flex-direction: row;
    align-items: center;
    height: unset;
    flex-wrap: wrap;
  }
  .lair {
    margin-bottom: 20px;
    width: 100px;
    height: 46px;
  }
  .compra,
  .lair,
  .compraworkShop,
  .comobasket {
    margin-top: 20px;
  }
  .mg {
    margin-top: 0px;
  }
  .secra {
    flex-direction: column;
    justify-items: center;
    align-items: center;
    margin-bottom: 0px !important;
  }
  /* .lair{
    margin-top: 20px;
}

    .como {
        margin-top: 20px;
    } */
}
