.mainSlid {
  width: 78px !important;
  height: 78px !important;
}

.innerImage {
  width: 100%;
  height: 100%;
}

.slidMian {
  height: 267px !important;
  width: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slidMian > .slick-prev {
  left: 4px !important;
  top: -35px !important;
  /* background: red; */
  /* transform: rotate(90deg) !important; */
  width: unset !important;
  height: 65px !important;
}

.slidMian > .slick-next {
  right: 9px !important;
  bottom: -98px !important;
  top: unset;
  /* background: green; */
  width: unset !important;
  height: 65px !important;
}

.slick-prev:before {
  content: url("../Images/upArrow.png");
  width: 38px !important;
  height: 65px !important;
}

.slick-next:before {
  content: url("../Images/downArrow.png");
  width: 38px !important;
  height: 65px !important;
}
