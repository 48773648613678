.builder {
    display: flex;
    max-width: 1440px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 136px;
}


.leftBuilder {
    display: flex;
    flex-direction: column;
    margin-top: 27px;
    max-width: 261px;
}

.headingArea {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.heading1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 41.2038px;
    line-height: 62px;
    text-align: center;
    color: #000000;
}


.detail {
    max-width: 202px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13.9561px;
    line-height: 21px;
    text-align: center;
    color: #000000;
}


.detailbox {
    margin-top: 24px;
    padding: 15px;
    border: 1px solid #000000;
    min-height: 419px;
}

.umezawa {
    font-family: 'Asap', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15.0459px;
    line-height: 23px;
    color: #000000;
}

.aplicar {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24.4753px;
    line-height: 37px;
    text-align: center;
    color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 61px;
    background: #FACB33;
    border-radius: 30px;
    margin-top: 17px;
    border: none;
}


.rightBuilder {
    display: flex;
    flex-direction: column;
    margin-left: 3.12%;
    width: 70%;
}

.buttonArea {
    display: flex;
}

.ponsible {
    margin-top: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    min-height: 70px;
    border-radius: 15px;
    font-family: 'Asap', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24.4753px;
    line-height: 37px;
    text-align: center;
    color: #000000;
    padding: 8px;
}

.cartas {
    display: flex;
    margin-top: 26px;
    justify-content: center;
    align-items: center;
    padding: 8px;
    font-family: 'Asap', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24.4753px;
    line-height: 37px;
    text-align: center;
    color: #000000;
    border-radius: 15px;
    width: 30%;
    margin-left: 17px;
}

.noEncon {
    display: flex;
    margin-top: 26px;
    justify-content: center;
    align-items: center;
    padding: 8px;
    font-family: 'Asap', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24.4753px;
    line-height: 37px;
    text-align: center;
    color: #000000;
    background: #FACB33;
    border: 1px solid #000000;
    border-radius: 15px;
    width: 30%;
    margin-left: 17px;

}


.imageArea {
    display: flex;
    margin-top: 50px;
    padding-left: 5.6%;
}

.detailText {
    display: flex;
    flex-direction: column;
}


.iloHeading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 35.034px;
    line-height: 53px;
    color: #17161F;
}

.losaNL {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 21.6386px;
    line-height: 96%;
    color: #17161F;
    max-width: 248px;
    margin-top: 30px;
}


.hemps {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13.8369px;
    line-height: 96%;
    color: #17161F;
    margin-top: 33px;
    max-width: 282px;
}

.prpertiesArea {
    margin-top: 43px;
    padding-left: 17px;
    padding-top: 18px;
    padding-right: 17px;
    padding-bottom: 8px;
    min-height: 222px;
    width: 294px;
    border: 1px solid #000000;
    border-radius: 7px;
}


.twig {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15.3274px;
    line-height: 96%;
    color: #17161F;
    margin-top: 5px;
}

.imageArea2 {
    margin-left: 14.9%;
    max-width: 367px;
    max-height: 276px;
}

.builderImage {
    width: 100%;
    height: 100%;
}


.lowerBuutons {
    display: flex;
    margin-top: 33px;
}

.cartasBuuton {
    margin-top: 22px;
    padding: 17px;
    width: 32.4%;
    border: 1px solid #000000;
    border-radius: 12px;
    font-family: 'Asap', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 23.6209px;
    line-height: 35px;
    color: #000000;
}

.totalButton {
    border: 1px solid #000000;
    border-radius: 12px;
    margin-top: 22px;
    padding: 17px;
    font-family: 'Asap', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 23.6209px;
    line-height: 35px;
    color: #000000;
    margin-left: 15px;
}

.agregar {
    margin-left: 15px;
    font-family: 'Asap', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24.4753px;
    line-height: 37px;
    text-align: center;
    color: #000000;
    background: #F17531;
    border-radius: 7px;
    margin-top: 22px;
    width: 32.4%;
}


.builderrr {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
}


@media (max-width:992px) {


    .builder {
        flex-direction: column;
        padding: 0;
        align-items: center;
    }


    .leftBuilder {
        max-width: 100%;
    }

    .aplicar {
        max-width: 265px;
    }

    .leftBuilder {
        width: 80%;
        flex-direction: column;
        align-items: center;
    }


    .detailbox {
        width: 100%;
        margin: 0;
    }

    .rightBuilder {
        margin-top: 30px;
        width: 100%;
        margin-left: 0;
    }


    .buttonArea {
        justify-content: center;
    }

}


@media (max-width:767px) {

    .heading1 {
        font-size: 35.2038px;
        line-height: 50px;
    }


    .detail {
        font-size: 13.9561px;
        line-height: 18px;
    }


    .detailbox {
        min-height: 350px;
    }


    .umezawa {
        font-size: 12.0459px;
        line-height: 20px;
    }

    .buttonArea {
        flex-wrap: wrap;
    }

    .imageArea {
        flex-direction: column;
        align-items: center;
        padding: 0;
    }

    .detailText {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }


    .prpertiesArea {
        width: 80%;
    }


    .imageArea2 {
        margin-top: 35px;
        margin-left: 0;
    }


}




@media (max-width:650px) {


    .buttonArea {
        flex-direction: column;
        align-items: center;
    }

    .cartas {
        margin-left: 0;
    }

    .noEncon {
        margin-left: 0;
    }


    .lowerBuutons {
        flex-direction: column;
        align-items: center;
    }

    .cartasBuuton {
        width: unset;
    }


    .totalButton {
        margin-left: 0;
    }


    .agregar {
        margin-left: 0;
        width: unset;
        padding: 17px;
    }

}


@media (max-width:450px) {

    .imageArea2 {
        width: 80%;
    }

    .ponsible {
        width: unset;
        padding: 15px;
        font-size: 20.4753px;
        line-height: 28px;
    }


    .cartas {
        width: unset;
        padding: 15px;
        font-size: 20.4753px;
        line-height: 28px;
    }

    .noEncon {
        width: unset;
        padding: 15px;
        font-size: 20.4753px;
        line-height: 28px;
    }


    .cartasBuuton {
        width: unset;
        font-size: 20.6209px;
        line-height: 28px;
    }


    .totalButton {
        margin-left: 0;
        font-size: 20.6209px;
        line-height: 28px;
    }


    .agregar {
        margin-left: 0;
        width: unset;
        padding: 17px;
        font-size: 20.6209px;
        line-height: 28px;
    }



}