.order {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: -37px;
  padding-bottom: 50px;
  min-height: 600px;
}

.orderInner {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.topButons {
  display: flex;
}

.firstbutton {
  font-family: "Hind";
  font-style: normal;
  font-weight: 400;
  font-size: 14.149px;
  line-height: 23px;
  color: #009ff9;
  width: 144px;
  height: 21px;
  background: #ffffff;
  border: 1.16334px solid #bcbbbb;
  text-align: center;
  margin-top: 25px;
}

.secondbutton {
  width: 170px;
  height: 42px;
  background: #f0f0f0;
  border: 1.16334px solid #000000;
  margin-left: 13px;
  margin-top: 25px;
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14.149px;
  line-height: 23px;
  color: #009ff9;
  display: flex;
  align-items: center;
  border-radius: 5px;
  justify-content: center;
  cursor: pointer;
}

.lowerTable {
  margin-top: 35px;
  width: 100%;
  margin-bottom: 50px;
}

.topRow {
  font-family: "Hind";
  font-style: normal;
  font-weight: 600;
  font-size: 13.7972px;
  line-height: 22px;
  color: #000000;
  max-width: 63px;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.tabData {
  font-family: "Hind";
  font-style: normal;
  font-weight: 400;
  font-size: 19.59825px;
  line-height: 25px;
  color: #17161f;
}

.textSaecr {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20.0724px;
  line-height: 30px;
  color: #000000;
}

.searchArea {
  display: flex;
  margin-top: 35px;
}

.serInput {
  width: 250px;
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16.0724px;
  line-height: 25px;
  color: #000000;
  margin-left: 15px;
  border-radius: 5px;
}

.confi {
  font-family: "Hind";
  font-style: normal;
  font-weight: 400;
  font-size: 21.2245px;
  line-height: 23px;
  color: #6d7488;
  width: 109.935px;
  height: 31.41px;
  text-align: center;
  background: #f0f0f0;
  border: 1.74501px solid #000000;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  /* margin: auto; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.dffdf {
  font-family: "Hind";
  font-style: normal;
  font-weight: 400;
  font-size: 21.2245px;
  line-height: 23px;
  color: #6d7488;

  text-align: center;
  background: #f0f0f0;
  border: 1.74501px solid #000000;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  /* margin: auto; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.confiblue {
  font-family: "Hind";
  font-style: normal;
  font-weight: 400;
  font-size: 21.2245px;
  line-height: 23px;
  width: 109.935px;
  height: 31.41px;
  text-align: center;
  background: #f0f0f0;
  border: 1.74501px solid #000000;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  /* margin: auto; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #009ff9;
}

.detl {
  font-family: "Hind";
  font-style: normal;
  font-weight: 400;
  font-size: 17.68625px; /* Increase the font size by about 25% */
  line-height: 23px; /* Maintain the line height */
  color: #6d7488;
  width: 82.8875px; /* Increase the width by about 25% */
  height: 26.175px; /* Increase the height by about 25% */
  text-align: center;
  background: #f0f0f0;
  border: 1.454175px solid #000000; /* Increase the border thickness by about 25% */
}

.inCam {
  max-width: 189px;
}

@media (max-width: 600px) {
  .topButons {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 992px) {
  .topButons {
    flex-wrap: wrap;
  }

  .orderInner {
    width: 97%;
  }
}

@media (max-width: 480px) {
  .searchArea {
    flex-direction: column;
    align-items: center;
  }
}
