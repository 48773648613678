.searchouter {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}
.fx {
  position: fixed;
  top: 297px;
  right: 10px;
}
.clicked {
  background-color: #ff0000; /* or whatever color you want on click */
}
.arrangement {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  padding: 20px 0px;
  align-items: center;
}
.ner {
  display: flex;
  flex-direction: column;
  margin-top: 33px;
  margin-bottom: 33px;
  position: relative;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  padding: 0px 10px;
}
.bili {
  height: 97px;
  width: 137px;
  position: absolute;
  right: 0px;
  top: -94px;
}
.khl {
  padding: 12px;
  border: 6px solid black;
  margin-bottom: 30px;
  font-weight: 800;
  border-radius: 8px;
}
.searchMain {
  display: flex;
  margin-top: 30px;
  width: 100%;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
}
.gfgf {
  margin-left: 18%;
  margin-top: 5%;
  width: 500px;
  margin-bottom: 40px;
}
.lao {
  justify-content: center;
  display: flex;
  height: 100%;
  /* align-items: center; */
  margin-top: 100px;
}
.leftColoum {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.carro {
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 27.1291px;
  line-height: 43px;
  color: #000000;
}

.carro1 {
  font-family: "Hind";
  font-style: normal;
  font-weight: 500;
  font-size: 27.1291px;
  line-height: inherit;
  text-align: center;
  color: #000000;
  display: none;
  padding: 7px 19px !important;
  width: auto;
  height: fit-content;
  background: #ffd44a !important;
}

.aplicra1 {
  background-color: #ffcf10;
  height: 40px;
  width: 100%;
  border-radius: 20px;
  outline: none;
  border: none;
  color: black;
  font-size: 25px;
  font-family: "Asap", sans-serif;
  font-weight: bold;
  margin-top: 10px;
  text-align: center;
  cursor: pointer;
}

.noPadding {
  width: 100%;
}

.busque {
  margin-top: 4px;
  font-family: "Hind";
  font-style: normal;
  font-weight: 400;
  font-size: 23.8264px;
  line-height: 38px;
  color: #000000;
}

.inputsArea1 {
  display: flex;
  flex-direction: column;
  margin-top: 45px;
}

.edition {
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18.4971px;
  line-height: 30px;
  color: #000000;
}

.reactsSlect {
  width: 195px;
  height: 27px;
  margin-top: 10px;
}

.line {
  width: 100%;
  border: 1px solid #000000;
  margin-top: 18px;
}

.arterArea {
  display: flex;
  margin-top: 20px;
  align-items: center !important;
}

.idioma {
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18.4971px;
  line-height: 30px;
  color: #000000;
  margin-top: 6px;
}

.anglesboX {
  display: flex;
  flex-direction: column;
  padding-left: 7px;
}

.chinnoArea {
  display: flex;
  align-items: center;
  margin-top: 4px;
}

.imgolia {
  width: 13px;
  height: 13px;
  margin-top: -5px;
}

.spanol {
  margin-left: 6px;
  font-family: "Hind";
  font-style: normal;
  font-weight: 400;
  font-size: 20.601px;
  line-height: 33px;
  color: #000000;
}

.subtippo {
  margin-top: 21px;
  font-family: "Hind";
  font-style: normal;
  font-weight: 700;
  font-size: 18.4971px;
  line-height: 30px;
  color: #000000;
}

.subtioInput {
  width: 191px;
  height: 22px;
  margin-top: 3px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #000000;
  border-radius: 18px;
  outline: none;
}

.aplicar {
  margin-top: 37px;
  width: 189px;
  height: 41px;
  background: #fecd36;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Hind";
  font-style: normal;
  font-weight: 700;
  font-size: 24.4239px;
  line-height: 39px;
  text-align: center;
  color: #000000;
}

.endColum {
  display: flex;
  flex-direction: column;
  width: 15%;
}

.endColum1 {
  display: none;
  flex-direction: column;
  margin-top: 20px;
}

.endInner {
  display: flex;
  padding-left: 10px;
  flex-direction: column;
}

.deom {
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20.0029px;
  line-height: 27px;
  color: #000000;
}

.elemon {
  font-family: "Hind";
  font-style: normal;
  font-weight: 400;
  font-size: 19.0708px;
  line-height: 31px;
  color: #000000;
}

.total {
  margin-top: 4px;
  font-family: "Hind";
  font-style: normal;
  font-weight: 400;
  font-size: 19.0708px;
  line-height: 31px;
  color: #000000;
}

.endLine {
  width: 250.5px;
  border: 1px solid #000000;
  margin-top: 17px;
}

.blastedArea {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding-left: 10px;
}

.blastedField {
  font-family: "Hind";
  font-style: normal;
  font-weight: 400;
  font-size: 16.2102px;
  line-height: 26px;
  color: #000000;
}
.btt {
  width: auto;
}
.activebt {
  background: #ffcd32;
  padding: 0px 26px;
  border-radius: 6px;
}
.activebtt {
  background: #bfbebc;
  padding: 0px 26px;
  border-radius: 6px;
}
.carroButton {
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24.4239px;
  line-height: 39px;
  text-align: center;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  background: #fecd36;
  border-radius: 20px;
  margin-top: 32px;
  border: none;
}

.centercoloum {
  display: flex;
  flex-direction: column;
  width: 62%;
  margin-top: 50px;
}

.innerCard {
  display: flex;
  flex-direction: column;
  margin-bottom: 57px;
}

.cardImageArea {
  display: flex;
}

.bander {
  width: 201px;
  height: 280px;
}

.solArea {
  display: flex;
  flex-direction: column;
  margin-left: 10%;
  align-items: center;
  max-width: 300px;
}

.topDesign {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20.0724px;
  line-height: 30px;
  color: #000000;
}

.solRing {
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 22.5865px;
  line-height: 36px;
  color: #007bff;
  margin-top: 10px;
}

.revised {
  font-family: "Hind";
  font-style: normal;
  font-weight: 400;
  font-size: 18.3421px;
  line-height: 29px;
  color: #000000;
}

.tipArea {
  width: 450px;
  min-height: 100p;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  font-family: "Hind";
  font-style: normal;
  font-weight: 400;
  font-size: 18.3421px;
  line-height: 29px;
  color: #000000;
  border: 1px solid black;
  border-radius: 20px;
}

.revisedImages {
  display: flex;
  margin-top: 40px;
  align-items: center;
  flex-direction: column;
}

.bander2 {
  width: 35px;
  height: 49px;
}

.bander3 {
  width: 38px;
  height: 38px;
  margin-top: 10px;
}

.chartArea {
  display: flex;
  padding-top: 26px;
  position: relative;
}

.num {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 19px;
  color: #000000;
  background: #f69e33;
  border: 1px solid #000000;
  border-radius: 5px;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
.idio {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 19px;
  color: #000000;
  background: #f69e33;
  border: 1px solid #000000;
  border-radius: 5px;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.stockInner {
  width: 40.11px;
  height: 39px;
  background: #facb33;
  border: 1.11429px solid #000000;
  border-radius: 5.57143px;
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14.2113px;
  line-height: 21px;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.numberArea {
  display: flex;
  align-items: center;
  justify-content: center;
}

.decremet {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 21px;
  height: 27.14px;
  background: #ffcf12;
  border-radius: 18px 0px 0px 18px;
  cursor: pointer;
}

.numberFielkd {
  width: 48.14px;
  height: 25.14px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15.07px;
  line-height: 23px;
  color: #000000;
  border: 0.5px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.priceCss {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20.0724px;
  line-height: 30px;
  color: #000000;
}

.increment {
  width: 21px;
  height: 27.14px;
  background: #ffcf12;
  border-radius: 0px 18px 18px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.shopp {
  width: 41px;
  height: 41px;
  border: 1px solid #000000;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.shopInner {
  width: 27px;
  height: 23px;
}

.foilz {
  display: flex;
  justify-content: center;
}

.sign1 {
  width: 67px;
  height: 25px;
}

.sign2 {
  width: 67px;
  height: 25px;
  margin-left: 20px !important;
}

.searchCat {
  position: absolute;
  width: 137px;
  height: 94px;
  right: 0;
  top: -64px;
}

.aplicar1 {
  background-color: #ffcf10;
  height: 40px;
  width: 100%;
  border-radius: 20px;
  outline: none;
  border: none;
  color: black;
  font-size: 25px;
  font-family: "asap", sans-serif;
  font-weight: bold;
}

.togleBorder {
  /* border: 2px solid #facb33 !important; */
  box-shadow: none !important;
  position: relative !important;
  border: none !important;
}

.namfluid {
  display: flex !important;
  justify-content: flex-start !important;
  padding: 0 !important;
}

.centerKp {
  display: block !important;
  width: 15%;
}

@media (max-width: 1250px) {
  .searchMain {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 992px) {
  .maw {
    width: 100%;
  }
  .maNd {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
@media (max-width: 1199px) {
  .carro1 {
    display: block;
  }

  .togleBorder {
    margin-left: 5%;
  }

  .aplicra1,
  .carroButton,
  .line {
    width: 80%;
  }

  .centercoloum {
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    width: 84%;
  }

  .searchMain {
    flex-direction: column;
    margin-top: 0px;
  }

  .endColum1 {
    display: flex;
  }

  .endColum {
    display: none;
  }
}

@media (max-width: 767px) {
  .searchCat {
    display: none;
  }

  .searchMain {
    display: block;
  }

  .bander {
    width: 171px;
    height: 235px;
  }

  .revisedImages {
    margin-top: 15px;
  }

  .bander3 {
    margin-left: 12px;
    width: 30px;
    height: 30px;
  }

  .bander2 {
    width: 30px;
    height: 37px;
  }

  .carro1 {
    font-size: 21.1291px;
    line-height: inherit;
    text-align: center;
  }

  .tipArea {
    width: 70%;
  }
  .gfgf {
    width: 80%;
    object-fit: contain;
    margin-bottom: 40px;
  }
}

/* css from builder  */

.builder {
  display: flex;
  max-width: 1440px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 136px;
}

.leftBuilder {
  /* display: flex;
      flex-direction: column; */
  margin-top: 27px;
  max-width: 261px;
}

.headingArea {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 41.2038px;
  line-height: 62px;
  text-align: center;
  color: #000000;
}

.detail {
  max-width: 202px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13.9561px;
  line-height: 21px;
  text-align: center;
  color: #000000;
}

.detailbox {
  margin-top: 24px;
  padding: 15px;
  border: 1px solid #000000;
  min-height: 419px;
}

.umezawa1 {
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15.0459px;
  line-height: 23px;
  color: #000000;
}

.aplicar {
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24.4753px;
  line-height: 37px;
  text-align: center;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 61px;
  background: #facb33;
  border-radius: 30px;
  margin-top: 17px;
  border: none;
}

.rightBuilder {
  display: flex;
  flex-direction: column;
  margin-left: 3.12%;
  width: 70%;
}

.buttonArea {
  display: flex;
}

.ponsible {
  margin-top: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  min-height: 70px;
  border-radius: 15px;
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24.4753px;
  line-height: 37px;
  text-align: center;
  color: #000000;
  padding: 8px;
}

.cartas {
  display: flex;
  margin-top: 26px;
  justify-content: center;
  align-items: center;
  padding: 8px;
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24.4753px;
  line-height: 37px;
  text-align: center;
  color: #000000;
  border-radius: 15px;
  width: 30%;
  margin-left: 17px;
}

.noEncon {
  display: flex;
  margin-top: 26px;
  justify-content: center;
  align-items: center;
  padding: 8px;
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24.4753px;
  line-height: 37px;
  text-align: center;
  color: #000000;
  background: #facb33;
  border: 1px solid #000000;
  border-radius: 15px;
  width: 30%;
  margin-left: 17px;
}

.lowerBuutons {
  display: flex;
  margin-top: 33px;
}

.cartasBuuton {
  margin-top: 22px;
  padding: 17px;
  width: 32.4%;
  border: 1px solid #000000;
  border-radius: 12px;
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 23.6209px;
  line-height: 35px;
  color: #000000;
}

.totalButton {
  border: 1px solid #000000;
  border-radius: 12px;
  margin-top: 22px;
  padding: 17px;
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 23.6209px;
  line-height: 35px;
  color: #000000;
  margin-left: 15px;
}

.agregar {
  margin-left: 15px;
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24.4753px;
  line-height: 37px;
  text-align: center;
  color: #000000;
  background: #f17531;
  border-radius: 7px;
  margin-top: 22px;
  width: 32.4%;
}

.centerArea {
  margin-top: 27px;
  width: 100%;
  max-width: 756px;
  margin-left: auto;
  margin-right: auto;
}

.centerCard {
  display: flex;
  flex-direction: column;
  margin-top: 33px;
  margin-bottom: 33px;
  position: relative;
}

.upperCards {
  display: flex;
  justify-content: space-between;
  margin-bottom: 22px;
  margin-top: 22px;
}

.centerImage {
  width: 133px;
  height: 186px;
}

.umezawa {
  display: flex;
  flex-direction: column;
  margin-left: 10%;
  /* max-width: 16.67%; */
  align-items: center;
  width: 100%;
}

.umeHeading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 21.0085px;
  line-height: 128%;
  text-align: center;
  color: #000000;
}

.umeFrom {
  margin-top: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14.0797px;
  line-height: 21px;
  text-align: center;
  color: #000000;
  min-width: max-content;
}

.kann2 {
  width: 24px;
  height: 29px;
  margin-top: 16px;
}

.numbu {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14.0797px;
  line-height: 21px;
  text-align: center;
  color: #000000;
  padding: 2px 8px;
  /* background: #c9c2c0; */
  margin-top: 10px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
}

.lore {
  display: flex;
  width: 100%;
}

.sinStack {
  margin-right: 5.59%;
  margin-top: auto;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24.4753px;
  line-height: 37px;
  text-align: center;
  color: #000000;
  margin-left: auto;
  margin-right: auto;
}

.topDesign {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20.0724px;
  line-height: 30px;
  color: #000000;
}

.numberArea {
  display: flex;
  align-items: center;
  justify-content: center;
}

.decremet {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 44px;
  background: #ffcf12;
  border-radius: 18px 0px 0px 18px;
  cursor: pointer;
}

.numberFielkd {
  width: 100px;
  height: 44px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 23px;
  color: #000000;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.increment {
  width: 28px;
  height: 44px;
  background: #ffcf12;
  border-radius: 0px 18px 18px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.dtovk {
  width: 44px;
  height: 44px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 19px;
  background: #facb33;
  border: 1.7127px solid #000000;
  border-radius: 8.56349px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.foils {
  width: 96px;
  height: 37px;
  /* border: 1px solid #000000; */
  border-radius: 2px;
  margin-left: auto;
  margin-right: auto;
}

.centerKp {
  display: block !important;
}

.togleBorder {
  /* border: 2px solid #ffd44a !important; */
  border: none !important;
  box-shadow: none !important;
  position: relative;
  height: fit-content !important;
}

.namfluid {
  /* display: flex !important; */
  justify-content: flex-start !important;
}

.carro1 {
  font-family: "Hind";
  font-style: normal;
  font-weight: 700;
  font-size: 27.1291px;
  line-height: inherit;
  text-align: center;
  color: #000000;
  display: none;
  padding: 7px 19px !important;
  width: auto;
  height: fit-content;
  background: #ffd44a !important;
}

@media (max-width: 1200px) {
  .umeFrom {
    min-width: auto;
  }
  .ponsible {
    font-size: 19.4753px;
    line-height: 25px;
  }

  .cartas {
    font-size: 19.4753px;
    line-height: 25px;
  }

  .noEncon {
    font-size: 19.4753px;
    line-height: 25px;
  }
  .cartasBuuton {
    font-size: 19.4753px;
    line-height: 25px;
  }

  .totalButton {
    font-size: 19.4753px;
    line-height: 25px;
  }

  .agregar {
    font-size: 19.4753px;
    width: 25.4%;
    line-height: 25px;
  }
}

@media (max-width: 991px) {
  .carro1 {
    display: block;
  }

  .rightBuilder {
    width: 100%;
    margin-left: 0;
  }

  .rightBuilder {
    display: block;
  }

  .builder {
    display: block;
  }
}

@media (max-width: 767px) {
  .ponsible {
    font-size: 13.4753px;
    line-height: 15px;
    min-height: 46px;
  }

  .cartas {
    font-size: 13.4753px;
    line-height: 15px;
    min-height: 46px;
  }

  .noEncon {
    font-size: 13.4753px;
    line-height: 15px;
    min-height: 46px;
  }

  .cartasBuuton {
    font-size: 13.4753px;
    line-height: 15px;
  }

  .totalButton {
    font-size: 13.4753px;
    line-height: 15px;
  }

  .agregar {
    font-size: 13.4753px;
    line-height: 15px;
    width: 25.4%;
  }
}
.mobileHide {
  display: inherit;
}
.mobileShow {
  display: none;
}
@media (max-width: 560px) {
  .arrangement{
    justify-content: space-between;
  }
  .centercoloum{
    width: 90%;
  }
  .centerCard {
    margin-top: 0px;
  }
  .upperCards {
    flex-direction: column;
    margin-bottom: 0px;
  }
  .ner {
    margin-top: 20px;
  }
  .mobileHide {
    display: none !important;
  }
  .mobileShow {
    display: block;
  }
  .sinStack {
    margin-top: 15px;
    font-size: 22.4753px;
    text-align: left;
  }
}

@media (max-width: 480px) {
  .buttonArea {
    flex-direction: column;
  }

  .cartas {
    margin-left: 0;
    margin-top: 9px;
  }

  .noEncon {
    margin-left: 0;
    margin-top: 9px;
  }

  .lowerBuutons {
    flex-direction: column;
  }

  .cartasBuuton {
    width: 126px;
  }

  .totalButton {
    width: 126px;
    margin-left: 0;
    padding: 8px;
  }

  .agregar {
    width: 126px;
    height: fit-content;
    margin-left: 0;
    padding: 8px;
  }
}
.linee {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.ccbutton {
  background-color: #ffcf10;
  height: 40px;
  width: 100%;
  border-radius: 20px;
  outline: none;
  border: none;
  color: black;
  font-size: 25px;
  font-family: "asap", sans-serif;
  font-weight: bold;
  cursor: pointer;
}
