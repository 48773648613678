.profle {
  display: flex;
  margin-top: -37px;
}

.ccbutton {
  background-color: #ffcf10;
  height: 40px;
  width: 100%;
  border-radius: 20px;
  outline: none;
  border: none;
  color: black;
  font-size: 25px;
  font-family: "asap", sans-serif;
  font-weight: bold;
  text-align: center;
  margin: auto 5px;
  margin-top: 40px;
  cursor: pointer;
}
.hrmb {
  margin-bottom: 1.5rem !important;
  margin-top: 1rem;

  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.titulo {
  font-size: 37px;
  font-family: "asap", sans-serif;
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.formPass {
  border-radius: 50px !important;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
}
.lab {
  margin-bottom: 0.5rem;
}
.yellowArea {
  display: flex;
  padding-left: 15px;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  min-height: 668px;
  background: #ffcd32;
  width: 35%;
  padding-right: 15px;
}

.heading {
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 58.1903px;
  line-height: 93px;
  text-align: center;
  color: #17161f;
}

.datosArea {
  margin-top: 22px;
  display: flex;
  flex-direction: column;
}

.datmis {
  display: flex;
  margin-top: 6px;
}

.dato1 {
  width: 38px;
  height: 44px;
}

.misDato {
  font-family: "Hind";
  font-style: normal;
  font-weight: 400;
  font-size: 34.35px;
  line-height: 55px;
  color: #17161f;
  margin-left: 24px;
}

.inputsArea {
  display: flex;
  flex-direction: column;
  padding-left: 7.5%;
  padding-top: 105px;
  padding-right: 15px;
  width: 65%;
}

.imageArea {
  display: flex;
  align-items: center;
}

.profileCat {
  width: 150px;
  height: 150px;
}

.javies {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 47px;
}

.javieriNput {
  max-width: 444px;
  width: 100%;
  padding-left: 20px;
  padding-right: 10px;
  background: #cccccc;
  border-radius: 15px;
  font-family: "Hind";
  font-style: normal;
  font-weight: 400;
  font-size: 26.3768px;
  line-height: 42px;
  color: #17161f;
  outline: none;
  border: none;
  padding-top: 6px;
  padding-bottom: 6px;
}

.javieriNput::placeholder {
  background: #cccccc;
}

.second {
  max-width: 444px;
  width: 100%;
  padding-left: 20px;
  padding-right: 10px;
  background: #cccccc;
  border-radius: 15px;
  font-family: "Hind";
  font-style: normal;
  font-weight: 400;
  font-size: 26.3768px;
  line-height: 42px;
  color: #17161f;
  outline: none;
  margin-top: 26px;
  border: none;
  padding-top: 6px;
  padding-bottom: 6px;
}

.second::placeholder {
  background: #cccccc;
}

.emailInput {
  max-width: 643px;
  width: 68.6%;
  padding-left: 20px;
  padding-right: 10px;
  background: #cccccc;
  border-radius: 15px;
  font-family: "Hind";
  font-style: normal;
  font-weight: 400;
  font-size: 26.3768px;
  line-height: 42px;
  color: #17161f;
  margin-top: 37px;
  outline: none;
  border: none;
  padding-top: 6px;
  padding-bottom: 6px;
}

.inputDtae {
  display: flex;
}

.dateput {
  margin-top: 32px;
  background: #cccccc;
  border-radius: 15px;
  padding-left: 24px;
  font-family: "Hind";
  font-style: normal;
  font-weight: 400;
  font-size: 26.3768px;
  line-height: 42px;
  color: #17161f;
  width: 33.6%;
  outline: none;
  max-width: 315px;
  border: none;
  padding-top: 6px;
  padding-bottom: 6px;
}

.dateput::placeholder {
  background: #cccccc;
}

.dateput1 {
  margin-left: 13px;
  margin-top: 32px;
  background: #cccccc;
  border-radius: 15px;
  padding-left: 24px;
  font-family: "Hind";
  font-style: normal;
  font-weight: 400;
  font-size: 26.3768px;
  line-height: 42px;
  color: #17161f;
  width: 33.6%;
  outline: none;
  max-width: 315px;
  border: none;
  padding-top: 6px;
  padding-bottom: 6px;
}

.dateput1::placeholder {
  background: #cccccc;
}

.Myprofile_myProff__owecc .Lower_secra__9HNSN {
  background: #ffcd32 !important;
}

@media (max-width: 992px) {
  .heading {
    font-size: 40.1903px;
    line-height: 50px;
  }

  .dato1 {
    width: 25px;
    height: 30px;
  }

  .misDato {
    font-size: 25.35px;
    line-height: 33px;
  }

  .yellowArea {
    justify-content: flex-start;
  }

  .inputsArea {
    padding-left: 2.5%;
    padding-top: 65px;
  }

  .imageArea {
    width: 100%;
  }

  .javies {
    width: 100%;
    margin-left: 20px;
  }

  .javieriNput {
    width: 75%;
    font-size: 20.3768px;
    line-height: 30px;
    padding-left: 14px;
  }

  .second {
    width: 75%;
    font-size: 20.3768px;
    line-height: 30px;
    padding-left: 14px;
    margin-top: 20px;
  }

  .profileCat {
    width: 110px;
    height: 110px;
  }

  .emailInput {
    width: 81.6%;
    font-size: 20.3768px;
    line-height: 30px;
  }

  .dateput {
    width: 40%;
    font-size: 20.3768px;
    line-height: 30px;
  }

  .dateput1 {
    width: 40%;
    font-size: 20.3768px;
    line-height: 30px;
  }
}

@media (max-width: 767px) {
  .profle {
    flex-direction: column;
  }

  .yellowArea {
    min-height: unset;
    padding-bottom: 30px;
    width: 100%;
    padding-top: 60px;
  }

  .inputsArea {
    width: 100%;
    padding-top: 30px;
    align-items: center;
    padding-bottom: 30px;
  }

  .profileCat {
    width: 95px;
    height: 95px;
  }

  .javieriNput {
    width: 100%;
    font-size: 17.3768px;
    line-height: 23px;
  }

  .second {
    width: 100%;
    font-size: 17.3768px;
    line-height: 23px;
    margin-top: 15px;
  }

  .javies {
    width: 60%;
  }

  .imageArea {
    justify-content: center;
  }

  .emailInput {
    font-size: 17.3768px;
    line-height: 23px;
    width: 76.6%;
  }

  .dateput1 {
    width: 50%;
    font-size: 17.3768px;
    line-height: 23px;
  }

  .dateput {
    width: 50%;
    font-size: 17.3768px;
    line-height: 23px;
  }

  .inputDtae {
    width: 76.6%;
  }
}

@media (max-width: 450px) {
  .inputDtae {
    width: 90%;
  }

  .emailInput {
    font-size: 17.3768px;
    line-height: 23px;
    width: 90%;
  }
}
