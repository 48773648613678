

.MuiTableRow-head{
    border: 2px solid #000000 !important;
    border-radius: 20px !important;
}

.cart{
    width: 100%;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 50px;
}

.MuiTableCell-alignRight{
    text-align: center !important;
}

.css-11xur9t-MuiPaper-root-MuiTableContainer-root{
    box-shadow: none !important;
}

.MuiTableRow-root{
    border-bottom: 1px solid black !important;
    
}


.tdd .MuiTableCell-root {
    padding: 5px !important;
}
