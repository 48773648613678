.navbar-toggler {
  background: white !important;
  box-shadow: none !important;
  position: absolute;
  left: 12px;
  padding: 0px !important;
  height: 32px;
  margin: 0px;
}
.topNavTog {
  width: 37px;
  height: 30px;
  object-fit: contain;
}
.topNavTogRight {
  display: none;
}
@media (max-width: 992px) {
  .container-fluid {
    justify-content: center !important;
    align-items: center !important;
  }
}

@media (max-width: 540px) {
  .navbar-toggler-icon {
    width: 16px !important;
    height: 16px !important;
  }
  .topNavTogRight {
    display: flex;
    box-shadow: none !important;
    position: absolute;
    right: 12px;
    padding: 0px;
    height: 32px;
    margin: 0px;
  }
  .alignmenTopNav {
    width: calc(100% - 37px) !important;
    margin: 0px !important;
    justify-content: center !important;
  }
}
