.cartInner {
  display: flex;
  flex-direction: column;
  max-width: 1440px;
}
.al {
 display: flex;
 justify-content: end;
}
.er {
  font-size: 20px;
  color: rgb(33, 37, 41);
  font-weight: 400;
  margin-left: 13.5px;
}
.lty {
  width: 160px;
}
.top {
  display: flex;
  margin-top: 32px;
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 50px;
  justify-content: center;
}

.leftcarro {
  background: rgba(255, 207, 16, 0.99);
  border: 1px solid #000000;
  border-radius: 12px 0px 0px 12px;
  width: 46.2%;
  min-height: 67px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24.045px;
  line-height: 36px;
  color: #000000;
  cursor: pointer;
}
.leftcarroActive {
  background: rgba(255, 255, 255, 0.4);
  border: 1px solid #000000;
  border-radius: 12px 0px 0px 12px;
  width: 46.2%;
  min-height: 67px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24.045px;
  line-height: 36px;
  color: #000000;
  cursor: pointer;
}
.rightCarro {
  background: rgba(255, 255, 255, 0.4);
  border: 1px solid #000000;
  border-radius: 0px 12px 12px 0px;
  width: 46.2%;
  min-height: 67px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24.045px;
  line-height: 36px;
  color: #000000;
  cursor: pointer;
}
.rightCarroActive {
  background: rgba(255, 207, 16, 0.99);
  border: 1px solid #000000;
  border-radius: 0px 12px 12px 0px;
  width: 46.2%;
  min-height: 67px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24.045px;
  line-height: 36px;
  color: #000000;
  cursor: pointer;
}
.tableee {
  width: 100%;
  max-width: 1360px;
  margin-left: auto;
  margin-right: auto;
}

.cartaArea {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cartAtext {
  /* max-width: 109px; */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 17.3961px;
  line-height: 26px;
  text-align: center;
  color: #007bff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.bander1 {
  width: 72px;
  height: 98px;
}

.num {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 19px;
  color: #000000;
  background: #f69e33;
  border: 1px solid #000000;
  border-radius: 5px;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.idio {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 19px;
  color: #000000;
  background: #f69e33;
  border: 1px solid #000000;
  border-radius: 5px;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.pricArea {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: 59px;
}

.totalPargo {
  font-family: "Hind", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 28.7704px;
  line-height: 43px;

  color: #000000;
}

.dollar {
  font-weight: 600;
  font-size: 28.77px;
  line-height: 49px;
}

.dicountArea {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.diucouynPrice {
  font-family: "Hind", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 28.7704px;
  line-height: 43px;

  margin-left: 10px;
}

.radioInput {
  width: 10px;
  height: 10px;
}

.radioArea {
  display: flex;
  align-items: center;
  justify-content: center;
}

.priceDetail {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14.7197px;
  line-height: 22px;

  color: #000000;
}

.comprara {
  margin-top: 14px;
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 25.5318px;
  line-height: 38px;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 135px;
  height: 66px;
  background: #f69e33;
  border: 1px solid #000000;
  border-radius: 20px;
  margin-left: auto;
}

.anadir {
  margin-left: auto;
  margin-top: 14px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 25.5318px;
  line-height: 38px;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 135px;
  height: 66px;
  background: #f69e33;
  border: 1px solid #000000;
  border-radius: 20px;
}

.sunii {
  max-width: 90%;
  width: 100%;
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 25.5318px;
  line-height: 38px;
  color: #000000;
  margin-left: auto;
  margin-right: auto;
}

.margLower1 {
  border-bottom: 1px solid black !important;
}

.comprar {
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24.8172px;
  line-height: 37px;
  color: #000000;
  background: #fdcf41;
  border: 1px solid #000000;
  border-radius: 18px;
  width: 137px;
  height: 67px;
  margin-left: auto;
  cursor: pointer;
}

.numberArea {
  display: flex;
  align-items: center;
  justify-content: center;
}

.decremet {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 44px;
  background: #ffcf12;
  border-radius: 18px 0px 0px 18px;
  cursor: pointer;
}

.numberFielkd {
  width: 100px;
  height: 44px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 23px;
  color: #000000;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.increment {
  width: 28px;
  height: 44px;
  background: #ffcf12;
  border-radius: 0px 18px 18px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.topRow {
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20.0724px;
  line-height: 30px;
  text-align: center;
  color: #000000;
}

.cross {
  width: 37px;
  height: 37px;
  background: #ffffff;
  border-radius: 0px 0px 1.78232px 0px;
  /* transform: rotate(180deg); */
  border: 1px solid;
  text-align: center;
  cursor: pointer;
  padding-top: 7px;
  justify-content: center;
}

.crossInner {
  font-size: 21px;
}

@media (max-width: 1070px) {
  .leftcarro {
    font-size: 19.045px;
  }

  .rightCarro {
    font-size: 19.045px;
  }
}
@media (max-width: 600px) {
  .al {
    display: flex;
    justify-content: start;
  }
  .er {
    font-size: 20px;
    color: rgb(33, 37, 41);
    font-weight: 400;
    margin-left: 15px;
    width: max-content;
    display: inline-block;
  }
  .lty {
    width: -webkit-fill-available;
  }
}
@media (max-width: 767px) {
  .top {
    flex-direction: column;
    align-items: center;
  }

  .leftcarro {
    width: 90%;
    border-radius: 10px;
    text-align: center;
  }

  .rightCarro {
    width: 90%;
    border-radius: 10px;
    text-align: center;
  }

  .totalPargo {
    font-size: 17.7704px;
    text-align: right;
  }

  .dollar {
    font-size: 19.77px;
    line-height: 34px;
  }

  .diucouynPrice {
    font-size: 17.7704px;
    line-height: 25px;
  }

  .dicountArea {
    align-items: end;
  }

  .priceDetail {
    font-size: 12.7197px;
    line-height: 22px;
  }

  .comprara {
    font-size: 20.5318px;
    line-height: 25px;
    width: 108px;
    height: 45px;
    border-radius: 13px;
  }

  .anadir {
    font-size: 20.5318px;
    line-height: 25px;
    width: 108px;
    height: 45px;
    border-radius: 13px;
  }

  .sunii {
    font-size: 18.5318px;
    line-height: 28px;
    margin-top: 7px;
    width: 95%;
  }
}

@media (max-width: 480px) {
  .comprar {
    width: 99px;
    height: 45px;
    border-radius: 12px;
    font-size: 18.8172px;
  }
}
