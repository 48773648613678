

.errordesign{
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
}



.error1 {
    display: flex;
    margin-top: 60px;
    max-width: 1337px;
    min-height: 731px;
    justify-content: space-between;
    border: 8px solid #000000;
    border-radius: 43px;
    margin-left: auto;
    margin-right: auto;
}

.textArea {
    margin-top: 93px;
    margin-left: 4.5%;
}


.errorHeading {
    font-family: 'Asap', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 103.137px;
    line-height: 165px;
    color: #17161F;
}

.errorDetail {
    margin-top: 48px;
    font-family: 'Hind';
    font-style: normal;
    font-weight: 400;
    font-size: 71.4862px;
    line-height: 129%;
    color: #17161F;
    max-width: 561px;
}


.fiImag {
    margin-top: 79px;
    margin-right: 4.72%;
    width: 35.2%;
    height: 400px;
}


.errorDetail2 {
    font-family: 'Asap', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 58.8312px;
    line-height: 129%;
    color: #17161F;
    max-width: 516px;
}


.hemos {
    font-family: 'Hind';
    font-style: normal;
    font-weight: 500;
    font-size: 29.8825px;
    line-height: 129%;
    color: #17161F;
    margin-top: 26px;
    max-width: 527px;
}


.hemos1 {
    margin-top: 26px;
    font-family: 'Hind';
    font-style: normal;
    font-weight: 500;
    font-size: 29.8825px;
    line-height: 129%;
    color: #17161F;
    max-width: 552px;
}


@media (max-width:1200px) {
    
.errorHeading{
    font-size: 70.137px;
    line-height: 100px;
}

.errorDetail{
    font-size: 50.4862px;
    line-height: 129%;
}


.errorDetail2{   
    font-size: 45.8312px;
    line-height: 129%;
}

.hemos{
    font-size: 25.8825px;
    line-height: 129%;
}


.hemos1{
    font-size: 25.8825px;
    line-height: 129%;
}


}

@media (max-width:767px) {

    .error1{
        flex-direction: column;
        align-items: center;
        padding-left: 15px;
        padding-right: 15px;
        border: 4px solid #000000;
        justify-content: unset;
    }
    
    .errorHeading{
        font-size: 45.137px;
        line-height: 65px;
    }
    
    .errorDetail{
        font-size: 35.4862px;
        line-height: 129%;
    }

    .error1{
        margin-bottom: 25px;
    }

}


@media (max-width:460px) {

    .errorHeading{
        font-size: 30.137px;
        line-height: 40px;
    }
    
    .errorDetail{
        font-size: 20.4862px;
        line-height: 129%;
        margin-top: 20px;
    }


    .fiImag{
        width: 65.2%;
        height: 330px;
    }

    .errorDetail2{   
        font-size: 30.8312px;
        line-height: 129%;
    }
    
    .hemos{
        font-size: 20.8825px;
    line-height: 129%;
    margin-top: 15px;
    }
    
    
    .hemos1{
        font-size: 20.8825px;
        line-height: 129%;
        margin-top: 15px;
    }
    

    
}


