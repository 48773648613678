.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
input::placeholder {
  color: #838383 !important;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.centerImageee {
  width: 133px;
  height: 186px;
}
.uppppp:hover .zome {
  display: block;
}
.magnifier {
  width: 230px;
  height: 320px;
}
.uppppp .zome {
  position: absolute;
  left: -9px;
  top: 2px;
  display: none;
  font-size: 27px;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
a {
  text-decoration: none !important;
}

/* 
table {
  border-collapse: seperate;
  border-spacing: 0;
}

tr th,
tr td {
  padding: 20px;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
}

tr th {
  border-top: 1px solid #000;
}

tr td:nth-child(1),
tr th:nth-child(1) {
  border-left: 1px solid #000;
}

tr th:nth-child(1) {
  border-radius: 10px 0 0 0;
}

tr th:nth-last-child(1) {
  border-radius: 0 10px 0 0;
}

tr:nth-last-child(1) td:nth-child(1) {
  border-radius: 0 0 0 10px;
}

tr:nth-last-child(1) td:nth-last-child(1) {
  border-radius: 0 0 10px 0;
} */

.css-13cymwt-control {
  border: 1px solid #838383 !important;
  border-radius: 22px !important;
}

.css-1u9des2-indicatorSeparator {
  display: none;
}

.css-tj5bde-Svg {
  color: #353535;
}

table {
  border-collapse: separate !important;
  border-spacing: 0 !important;
}
th {
  border: 1px solid #000000 !important;
  border-left: 0px solid #000000 !important;
  border-right: 0px solid #000000 !important;
}
.tdd {
  width: 100%;
}
.tdd th:first-child {
  border-left: 1px solid #000000 !important;
  border-top-left-radius: 30px !important;
  border-bottom-left-radius: 30px !important;
}
.tdd th:last-child {
  border-right: 1px solid #000000 !important;
  border-top-right-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
}
td {
  text-align: center !important;
}
