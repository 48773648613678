.home {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background: linear-gradient(#141e30, #243b55);
  display: flex;
  min-height: 100vh;
}

.logArea {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
}

.loginBox {
  width: 400px;
  padding: 40px;
  background: linear-gradient(90deg, #fff000 0%, #f47721 100%);
  box-sizing: border-box;
  box-shadow: 0 15px 25px rgb(0 0 0 / 60%);
  border-radius: 10px;
}

.heading {
  margin: 0 0 30px;
  padding: 0;
  color: #fff;
  text-align: center;
  font-size: 15px;
  font-weight: 700;
}
.submitt {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: #03e9f4;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  margin-top: 40px;
  letter-spacing: 4px;
  background: none;
  border: none;
}

.submitt:hover {
  background: #03e9f4;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #03e9f4, 0 0 25px #03e9f4, 0 0 50px #03e9f4,
    0 0 100px #03e9f4;
}

.logArea {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
}

.userHeading {
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  pointer-events: none;
  transition: 0.5s;
}

.selectStyle {
  width: 171px;
  height: 36px;
}

.selectStyle1 {
  width: 80px;
  height: 36px;
}

.optionsStyl {
  font-size: 13px;
  font-weight: 400px;
  font-family: Arial, Helvetica, sans-serif;
  color: black;
}

.signedArea {
  display: flex;
  margin-top: 20px;
  margin-bottom: 30px;
  align-items: center;
}

.signedHeading {
  color: #fff;
  font-size: 14px;
}

.singedInput {
  margin-left: 15px;
  width: 12px;
  height: 12px;
}

.fileArea {
  display: flex;
  flex-direction: column;
}

.fileHeading {
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
}

.fileInput {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
}

@media (max-width: 767px) {
  .home {
    flex-direction: column;
  }

  .logArea {
    width: 100%;
  }
}

@media (max-width: 550px) {
  .loginBox {
    width: 80%;
  }

  .submitt {
    margin-top: 20px;
  }
}
