.created {
    width: 100%;

}

.createdInner {
    width: 100%;
    height: 836px;
    background-image: url(../Home/HomeImages/created.png);
    background-size: cover;
    background-position: center;
}

.textArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 250px;
}


.heading{
    font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 54.7692px;
line-height: 82px;
color: #17161F;
}

.bieno{
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 24.045px;
line-height: 36px;
color: #000000;
margin-top: 37px;
}


.accCato{
    width: 176px;
height: 213px;
margin-top: 96px;
margin-left: 250px;
}

.ira{
    font-family: 'Asap', sans-serif;
font-style: normal;
font-weight: 700;
font-size: 36.0676px;
line-height: 54px;
color: #000000;
background: rgba(255, 207, 16, 0.99);
width: 369px;
height: 85px;
display: flex;
justify-content: center;
align-items: center;
border-radius: 36px;
border: none;
}


@media (max-width:992px) {

    .heading{
        font-size: 45.7692px;
        line-height: 63px;
    }


    .bieno{
        font-size: 24.045px;
    line-height: 30px;
    }


    .accCato{
        margin-left: 220px;
    }


    .ira{
        border-radius: 31px;
        font-size: 32.0676px;
    line-height: 49px;
    width: 332px;
    }
    
}



@media (max-width:480px) {

    .heading{
        font-size: 30.7692px;
        line-height: 35px;
    }


    .bieno{
        font-size: 20.045px;
        line-height: 25px;
    }


    .accCato{
        margin-left: 157px;
        width: 150px;
        height: 180px;
    }


    .ira{
        border-radius: 26px;
        font-size: 25.0676px;
        line-height: 26px;
        width: 255px;
        height: 66px;
    }
    
}