

.tamasOuter{
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;

}


.workname {
    display: flex;
    margin-top: 47px;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
}

.temasRea {
    display: flex;
    margin-left: 12.8%;
    flex-direction: column;
}

.laion {
    font-family: 'Hind', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 30.8982px;
    line-height: 142%;
    text-align: center;
    color: #000000;
    max-width: 235px;
}


.temesRIght {
    display: flex;
    margin-left: 20px;
    align-items: center;
}


.teeemHeading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 70.4452px;
    line-height: 106px;
    text-align: center;
    color: #000000;
    margin-right:45px;
}


.sonlos {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16.7115px;
    line-height: 25px;
    color: #000000;
    max-width: 598px;
}


.defaultArea {
    margin-top: 7px;
    display: flex;
    justify-content: space-between;
    max-width: 850px;
    width: 100%;
    flex-wrap: wrap;
}


.defaArea{
    display: flex;
    flex-direction: column;
    margin-left: 2.5%;
}


@media (max-width:1280px) {


    .temasRea{
        margin-left: 4.8%;
    }
    
}


@media (max-width:992px) {


    .workname{
        flex-direction: column;
        align-items: center;
    }
    
.temasRea{
    flex-direction: row;
    align-items: center;
    margin-left: 0;
}


.laion{
    margin-right: 10%;
}

.defaArea{
    margin-top: 30px;
}


}


@media (max-width:767px) {


.defaArea{
    margin-left: 0;
}


.temesRIght{
    margin-left: 0;
    align-items: center;
    flex-direction: column;
}

.teeemHeading{
    font-size: 50.4452px;
    line-height: 60px;
}    


.sonlos{
    text-align: center;
}


}


@media (max-width:550px) {


.temasRea{
    flex-direction: column;
}

.laion{
    margin-right: 0;
}    


}