.login {
  display: flex;
  justify-content: center;
  width: 100%;
}

.leftPart {
  display: flex;
  width: 38.4%;
  padding-top: 197px;
  background: #ffcd32;
  padding: 197px 15px 15px 15px;
}

.outer {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.leftTextArea {
  margin-right: 20%;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loginBlack {
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 57.7297px;
  line-height: 92px;
  text-align: center;
  color: #17161f;
  max-width: 312px;
}

.crearButton {
  width: 364px;
  background: #000000;
  border-radius: 36px;
  height: 82px;
  margin-top: 82px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 38.4865px;
  line-height: 62px;
  color: #ffffff;
}

.tienes {
  margin-top: 11px;
  font-family: "Hind";
  font-style: normal;
  font-weight: 500;
  font-size: 23.558px;
  line-height: 129%;
  color: #000000;
}

.fieldArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 927px;
  margin-right: auto;
  width: 61.6%;
  padding: 216px 15px 30px 15px;
}

.tucenya {
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 70.992px;
  line-height: 114px;
  color: #17161f;
}

.correo {
  background: #cccccc;
  border-radius: 20px;
  margin-top: 10px;
  width: 80%;
  max-width: 607px;
  height: 45px;
  font-family: "Hind";
  font-style: normal;
  font-weight: 600;
  font-size: 24.1105px;
  line-height: 39px;
  color: #000000;
  padding-left: 20px;
  padding-right: 15px;
  border: none;
  outline: none;
}

.correo::placeholder {
  color: #ffffff;
}

.clave {
  outline: none;
  border: none;
  background: #cccccc;
  border-radius: 20px;
  margin-top: 42px;
  width: 80%;
  max-width: 607px;
  height: 45px;
  font-family: "Hind";
  font-style: normal;
  font-weight: 600;
  font-size: 24.1105px;
  line-height: 39px;
  color: #000000;
  padding-left: 20px;
  padding-right: 15px;
}

.clave::placeholder {
  color: #ffffff;
}

.deatil {
  margin-top: 18px;
  font-family: "Hind";
  font-style: normal;
  font-weight: 500;
  font-size: 23.558px;
  line-height: 129%;
  color: #cccccc;
}

.logincat {
  margin-top: 18px;
  max-width: 232px;
  width: 232px;
  height: 176px;
  margin-left: 32%;
}

.iniciar {
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 50.9015px;
  line-height: 81px;
  text-align: center;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffcd32;
  border-radius: 36px;
  max-width: 610px;
  width: 80%;
  min-height: 68px;
  border: none;
  outline: none;
}

.confirm {
  margin-top: 25px;
  max-width: 1316px;
  width: 100%;
  min-height: 845px;
  padding-top: 107px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 3px solid #ffcd32;
  border-radius: 22px;
  margin-left: auto;
  margin-right: auto;
}

.confirmHeading {
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 66.0934px;
  line-height: 106px;
  color: #17161f;
}

.detailTe {
  font-family: "Hind";
  font-style: normal;
  font-weight: 600;
  font-size: 25.5369px;
  line-height: 41px;
  text-align: center;
  color: #17161f;
  margin-top: 25px;
  max-width: 600px;
}

.coreee {
  font-family: "Hind";
  font-style: normal;
  font-weight: 600;
  font-size: 24.1105px;
  line-height: 39px;
  color: #ffffff;
  margin-top: 56px;
  background: #cccccc;
  border-radius: 20px;
  padding-left: 20px;
  padding-right: 10px;
  max-width: 607px;
  width: 46%;
  outline: none !important;
  border: none;
}

.coreee::placeholder {
  color: #ffffff;
}

.ImageArea {
  display: flex;
  align-items: center;
  padding-left: 12%;
}

.Hind {
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 34.5323px;
  line-height: 55px;
  text-align: center;
  color: #ffffff;
  background: #ffcd32;
  border-radius: 33px;
  width: 333px;
  height: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.catcat {
  width: 163px;
  height: 329px;
  margin-left: 75px;
}

@media (max-width: 1250px) {
  .coreee {
    width: 80%;
  }

  .Hind {
    width: 263px;
    height: 58px;
    font-size: 29.5323px;
    line-height: 38px;
  }
}

@media (max-width: 767px) {
  .catcat {
    width: 140px;
    height: 280px;
    margin-left: 75px;
  }

  .coreee {
    margin-top: 40px;
    font-size: 24.1105px;
    line-height: 39px;
  }

  .detailTe {
    font-size: 20.5369px;
    line-height: 25px;
    margin-top: 25px;
  }

  .confirmHeading {
    font-size: 35.0934px;
    line-height: 48px;
  }

  .confirm {
    padding-top: 60px;
    min-height: fit-content;
    padding-bottom: 90px;
  }
}

@media (max-width: 600px) {
  .catcat {
    width: 110px;
    height: 201px;
    margin-left: 27px;
  }

  .Hind {
    width: 213px;
    height: 47px;
    font-size: 24.5323px;
    line-height: 30px;
  }

  .ImageArea {
    padding-left: 8%;
  }

  .coreee {
    font-size: 20.1105px;
    line-height: 36px;
  }

  .confirmHeading {
    font-size: 30.0934px;
    line-height: 37px;
  }

  .detailTe {
    font-size: 18.5369px;
    line-height: 22px;
  }
}

@media (max-width: 450px) {
  .catcat {
    width: 101px;
    height: 168px;
    margin-left: 8px;
  }

  .Hind {
    width: 180px;
    height: 42px;
    font-size: 20.5323px;
    line-height: 21px;
  }
}

@media (max-width: 992px) {
  .leftPart {
    padding-top: 100px;
  }

  .leftTextArea {
    margin: 0 !important;
    align-items: center !important;
  }

  .loginBlack {
    font-size: 40.7297px;
    line-height: 50px;
  }

  .crearButton {
    width: 260px;
    background: #000000;
    border-radius: 36px;
    height: 57px;
    margin-top: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Hind";
    font-style: normal;
    font-weight: 700;
    font-size: 28.4865px;
    line-height: 62px;
    color: #ffffff;
  }

  .tienes {
    font-size: 20.558px;
    line-height: 129%;
  }

  .fieldArea {
    padding-top: 100px;
  }

  .tucenya {
    font-size: 40.992px;
    line-height: 50px;
  }

  .correo {
    height: 40px;
    font-size: 18.1105px;
    line-height: 30px;
  }

  .clave {
    height: 40px;
    font-size: 18.1105px;
    line-height: 30px;
    margin-top: 18px;
  }

  .deatil {
    font-size: 20.558px;
    line-height: 129%;
  }

  .logincat {
    width: 184px;
    height: 138px;
  }

  .iniciar {
    font-family: "Hind";
    font-style: normal;
    font-weight: 700;
    font-size: 32.9015px;
    line-height: 70px;
    text-align: center;
    color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffcd32;
    border-radius: 36px;
    max-width: 610px;
    width: 66%;
    min-height: 5px;
    border: none;
    outline: none;
  }
}

@media (max-width: 767px) {
  .login {
    flex-direction: column;
    align-items: center;
  }

  .leftPart {
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .fieldArea {
    width: 100%;
  }
}

@media (max-width: 450px) {
  .leftPart {
    padding-top: 50px;
  }

  .loginBlack {
    font-size: 30.7297px;
    line-height: 40px;
  }

  .crearButton {
    width: 206px;
    background: #000000;
    border-radius: 36px;
    height: 44px;
    margin-top: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Hind";
    font-style: normal;
    font-weight: 700;
    font-size: 21.4865px;
    line-height: 54px;
    color: #ffffff;
  }

  .fieldArea {
    padding-top: 50px;
  }

  .tucenya {
    font-size: 30.7297px;
    line-height: 40px;
  }

  .correo {
    height: 35px;
    font-size: 15.1105px;
    line-height: 24px;
  }

  .clave {
    height: 35px;
    font-size: 15.1105px;
    line-height: 24px;
  }

  .deatil {
    font-size: 17.558px;
    line-height: 129%;
  }

  .logincat {
    width: 110px;
    height: 88px;
    margin-left: 25%;
  }

  .iniciar {
    font-family: "Hind";
    font-style: normal;
    font-weight: 700;
    font-size: 22.9015px;
    line-height: 46px;
    text-align: center;
    color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffcd32;
    border-radius: 30px;
    max-width: 610px;
    width: 56%;
    min-height: 5px;
    border: none;
    outline: none;
  }
}
