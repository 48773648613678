.home{
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    background: linear-gradient(#141e30, #243b55);
    display: flex;
    min-height: 100vh;
}

.logArea{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
}


.loginBox {
    width: 400px;
    padding: 40px;
    background: linear-gradient(90deg, #FFF000 0%, #F47721 100%);
    box-sizing: border-box;
    box-shadow: 0 15px 25px rgb(0 0 0 / 60%);
    border-radius: 10px;
}
.heading {
    margin: 0 0 30px;
    padding: 0;
    color: #fff;
    text-align: center;
    font-size: 15px;
    font-weight: 700;
}

.inputArea{
    display: flex;
align-items: center;
}

.putTlt{
    font-size: 10px;
    font-weight: 400;
    color: white;
}

.numInput{
    width: 177px;
    height: 30px;
    background: white;
    color: black;
    outline: none;
    border: none;
    border-radius: 8px;
    margin-left: 5px;
    padding: 0 8px;
}


.subButton{
    padding: 1px 6px;
    background: white;
    border-radius: 5px;
    margin-top: 12px;
    font-size: 12px;
    border: none;
    padding: 5px;
}

@media (max-width:767px) {

    .home {
        flex-direction: column;
    }

.logArea{
    width: 100%;
}

}



@media (max-width:550px) {


    .loginBox {
        width: 80%;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .submitt {
        margin-top: 20px;
    }


    .inputArea{
        flex-direction: column;
        align-items: center;
    }

.subButton{
    margin-top: 20px;
}


.numInput{
margin-top: 10px;
}

}