.detailArea {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.pfont {
  font-size: 25px;
}
.detailIner {
  background: rgba(255, 255, 255, 0.23);
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  width: 94%;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}

.detailmost {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.uperArea {
  margin-bottom: 31px;
  padding-top: 40px;
  padding-bottom: 40px;
}

.topRow {
  font-family: "Hind";
  font-style: normal;
  font-weight: 600;
  font-size: 21.0526px;
  line-height: 34px;
  color: #000000;
}

.topRow1 {
  font-family: "Hind";
  font-style: normal;
  font-weight: 600;
  font-size: 16.0526px;
  line-height: 34px;
  color: #000000;
  max-width: 118px;
  margin-left: auto;
  margin-right: auto;
}

.aboid {
  max-width: 731px;
  font-family: "Hind";
  font-style: normal;
  font-weight: 400;
  font-size: 18.0526px;
  line-height: 34px;
  color: #000000;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 950px) {
  .detailIner {
    width: 100%;
    box-shadow: none;
  }
  .topRow1{
    font-size:20px;
  }
}

@media (max-width: 500px) {
  .detailIner {
    width: 100%;
    box-shadow: none;
  }
}
