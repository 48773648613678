.home {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    background: linear-gradient(#141e30, #243b55);
    display: flex;
    min-height: 100vh;
}

.logArea {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    padding-top: 100px;
    padding-bottom: 100px;
}


.loginBox {
    width: 900px;
    padding: 20px;
    background: linear-gradient(90deg, #FFF000 0%, #F47721 100%);
    box-sizing: border-box;
    box-shadow: 0 15px 25px rgb(0 0 0 / 60%);
    border-radius: 10px;
}

.heading {
    margin: 0 0 30px;
    padding: 0;
    color: #fff;
    text-align: center;
    font-size: 15px;
    font-weight: 700;
}

.inputArea {
    display: flex;
    align-items: center;
}

.putTlt {
    font-size: 10px;
    font-weight: 400;
    color: white;
}

.numInput {
    width: 177px;
    height: 30px;
    background: white;
    color: black;
    outline: none;
    border: none;
    border-radius: 8px;
    margin-left: 5px;
    padding: 0 8px;
}


.adding {
    border: none;
    border-radius: 7px;
    padding: 5px;
    margin-bottom: 30px;
}


.subButton {
    padding: 1px 6px;
    background: white;
    border-radius: 5px;
    margin-top: 12px;
    font-size: 12px;
    border: none;
    padding: 5px;
}

.launchButton {
    background-color: white !important;
    color: black !important;
    border: none !important;
    margin-bottom: 20px;
}

.numer {
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.inputNumber {
    height: 30px;
    border-radius: 5px;
    padding: 5px 8px;
    outline: none;
}

.numTag{
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
}

.contr{
    display: flex;
    align-items: center;
    margin-top: 8px;
}

.inputcheck{
    width: 15px;
    height: 15px;
    margin-left: 5px;
}



@media (max-width:1170px) {

    .loginBox{
        width: 700px;
    }
    
}


@media (max-width:950px) {

    .loginBox{
        width: 540px;
    }
    
}


@media (max-width:767px) {

    .home {
        flex-direction: column;
    }

    .logArea {
        width: 100%;
        padding-top: 0;
    }

}



@media (max-width:590px) {


    .loginBox {
        width: 80%;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .submitt {
        margin-top: 20px;
    }


    .inputArea {
        flex-direction: column;
        align-items: center;
    }

    .subButton {
        margin-top: 20px;
    }


    .numInput {
        margin-top: 10px;
    }

}