.cards1 {
    margin-left: 13px;
    background: rgba(255, 255, 255, 0.3);
    border: 1px solid #FFCD32;
    box-shadow: 3px 4px 8px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Asap', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13.7938px;
    line-height: 142%;
    text-align: center;
    color: #000000;
    padding: 12px;
    min-width: 145px;
    min-height: 45px;
    margin-bottom: 13px;
    cursor: pointer;
}


.cards2 {
    margin-left: 13px;
    background: #FFCD32;
    box-shadow: 3px 4px 8px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Asap', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13.7938px;
    line-height: 142%;
    text-align: center;
    color: #000000;
    padding: 12px;
    min-width: 145px;
    min-height: 45px;
    margin-bottom: 13px;
    cursor: pointer;
}