.loginAdmin {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    background: linear-gradient(#141e30, #243b55);
    min-height: 100vh;
}


.navbAR {
    background: #17161F;
    border-bottom: 1px solid #0003;
    box-shadow: 0 0 32px #0003;
    font-size: 20px;
    font-weight: 300;
}

.home {
    color: #9ab;
    padding: 0.75em;
    text-align: center;
    text-decoration: none;
    transition: all .5s;
    width: 11.11%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.home:hover {
    background: #ffffff06;
    color: #adf;
}


.loginBox {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 400px;
    padding: 40px;
    transform: translate(-50%, -50%);
    background: linear-gradient(90deg, #FFF000 0%, #F47721 100%);
    /* background: #ffcf10; */
    box-sizing: border-box;
    box-shadow: 0 15px 25px rgb(0 0 0 / 60%);
    border-radius: 10px;
}


.heading {
    margin: 0 0 30px;
    padding: 0;
    color: #fff;
    text-align: center;
    font-size: 15px;
    font-weight: 700;
}

.mailTitlt {
    color: #fff;
    font-size: 14px;
}

.emailinput {
    width: 100%;
    padding: 6px 0;
    font-size: 16px;
    color: #fff;
    margin-bottom: 30px;
    border: none;
    border-bottom: 1px solid #fff;
    outline: none;
    background: transparent;
}

.submitt {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    color: #fff;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    margin-top: 40px;
    letter-spacing: 4px;
    background: none;
    border: none;
}

.submitt:hover {
    background: #03e9f4;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #03e9f4, 0 0 25px #03e9f4, 0 0 50px #03e9f4, 0 0 100px #03e9f4;
}


@media (max-width:767px) {

    .home {
        width: 28%;
    }



}



@media (max-width:550px) {

    .home {
        width: 28%;
    }

    .loginBox {
        width: 80%;
    }

    .emailinput {
        margin-bottom: 15px;
    }


    .submitt {
        margin-top: 20px;
    }


}