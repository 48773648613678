
.cum{
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.cumas1{
    font-family: 'Asap', sans-serif;
font-style: normal;
font-weight: 500;
font-size: 2.5rem;
line-height: 45px;
color: #FF9F37;
}

.cumpas2{
    font-family: 'Hind';
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 35px;
text-align: center;
color: #000000;

}