.preview {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  padding-right: 15px;
}
.jkl {
  display: flex;
  width: 100%;
  justify-content: center;
  height: 254px;
  align-items: center;
}
.PreviewLair1 {
  width: 100%;
  height: 373px;
}

.textDr {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1440px;
  align-items: center;
  margin-top: 47px;
  margin-left: auto;
  margin-right: auto;
}

.line {
  width: 301px;
  border: 2px solid #615f5f;
  height: 0;
}

.secro {
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 46.2867px;
  line-height: 57px;
  color: #000000;
}

.adipi {
  font-family: "Hind";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 115.1%;
  text-align: center;
  color: #4b4a4c;
  margin-top: 47px;
  max-width: 1194px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.cardArea {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1210px;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-around;
  margin-bottom: 60px;
}

.card1 {
  margin-top: 50px;
  width: 265px;
  height: 541px;
  border: 1px solid #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.laircard1 {
  width: 100%;
  height: 263px;
}

.cardText {
  display: flex;
  flex-direction: column;
  height: 139px;
  align-items: center;
  justify-content: center;
}

.culture {
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 23.6422px;
  line-height: 29px;
  color: #000000;
}

.prce {
  font-family: "Hind";
  font-style: normal;
  font-weight: 500;
  font-size: 23.3466px;
  line-height: 37px;
  color: #000000;
  margin-top: 6px;
}

.blackcolo {
  width: 100%;
  height: 139px;
  background: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fin {
  font-family: "Hind";
  font-style: normal;
  font-weight: 500;
  font-size: 24.0917px;
  line-height: 39px;
  margin-top: 5px;
  color: #ffffff;
}

.date {
  font-family: "Hind";
  font-style: normal;
  font-weight: 500;
  font-size: 24.0917px;
  line-height: 39px;
  color: #c1b193;
}

.preventa1 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24.0917px;
  line-height: 39px;
  color: #000000;
  width: 250px;
  height: 80px;
  border: 2px solid black;
  border-radius: 20px;
  background: white;
}

.preventa2 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Hind";
  font-style: normal;
  font-weight: 500;
  font-size: 24.0917px;
  line-height: 39px;
  color: #000000;
  width: 250px;
  height: 80px;
  border: 2px solid black;
  border-radius: 20px;
  background: white;
}

.fadeYello {
  width: 194px;
  height: 40px;
  margin-top: 9px;
  background: #f69e33;
  border-radius: 7px;
  font-family: "Asap", sans-serif;
  border: none;
}

.slectionButons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 700px;
  margin-top: 35px;
  margin-left: auto;
  margin-right: auto;
}

.fadeYello1 {
  width: 194px;
  height: 40px;
  margin-top: 9px;
  background: beige;
  border-radius: 7px;
  border: none;
  font-family: "Asap", sans-serif;
}

@media (max-width: 1090px) {
  .textDr {
    justify-content: space-around;
  }

  .line {
    width: 200px;
  }

  .adipi {
    width: 90%;
  }
}

@media (max-width: 992px) {
  .PreviewLair1 {
    height: 290px;
  }

  .secro {
    font-size: 35.2867px;
    line-height: 48px;
  }
}

@media (max-width: 767px) {
  .PreviewLair1 {
    height: 234px;
  }

  .secro {
    font-size: 35.2867px;
    line-height: 48px;
  }

  .line {
    width: 110px;
  }

  .secro {
    font-size: 30.2867px;
    line-height: 36px;
  }

  .adipi {
    font-size: 20px;
    line-height: 115.1%;
    margin-top: 20px;
  }
}

@media (max-width: 550px) {
  .line {
    width: 50px;
  }

  .secro {
    font-size: 25.2867px;
    line-height: 31px;
  }

  .adipi {
    width: 95%;
  }
}

@media (max-width: 450px) {
  .PreviewLair1 {
    height: 210px;
  }
}
