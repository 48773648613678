.shCenter{
    align-items: center !important;
    justify-content: center !important;
}

.MuiTableCell-root{
    text-align: center !important;
}

.editArea{
    display: flex;
    justify-content: center;
}

.editTag{
    width: 20px;
    height: 20px;
    fill: rgb(56, 90, 104);
}

.topRow{
    font-family: 'Asap', sans-serif;
font-style: normal;
font-weight: 500;
font-size: 16.0724px;
text-align: center;
color: #000000;
}