.workshop1 {
    display: flex;
    margin-top: 42px;
    max-width: 1440px;
    width: 100%;
    margin-bottom: 115px;
    margin-left: auto;
    margin-right: auto;

}


.padd{
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
}

.commanderArea {
    display: flex;
    flex-direction: column;

}


.commanheading {
    font-family: 'Asap', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 44.2075px;
    line-height: 66px;
    text-align: center;
    color: #000000;
    max-width: 244px;
}


.commanInput {
    background: rgba(217, 217, 217, 0.26);
    border: 1px solid #000000;
    margin-top: 32px;
    width: 260px;
    padding: 8px;
}

.colo {
    display: flex;
    flex-direction: column;
    margin-top: 9px;
    margin-left: 20px;
    align-items: flex-start;
}


.colorHeading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15.6027px;
    line-height: 23px;
    text-align: center;
    color: #000000;
}


.colorInput {
    width: 19px;
    height: 19px;
    background: rgba(217, 217, 217, 0.26);
    border: 1px solid #000000;

}


.aplicar {
    width: 256px;
    height: 60px;
    background: #FFCD32;
    border-radius: 25px;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Asap', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 25.4293px;
    line-height: 38px;
    text-align: center;
    color: #000000;
    border: none;
}

.aquiArea {
    display: flex;
    flex-direction: column;
    margin-left: 5.3%;
    align-items: center;
}

.aquiText {
    font-family: 'Hind', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 23.44px;
    line-height: 142%;
    text-align: center;
    color: #000000;
    width: 100%;
    max-width: 1018px;
}


.cardArea {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

}



.card1 {
    margin-top: 22px;
    border: 2px solid #000000;
    border-radius: 23px;
    width: 232px;
    height: 431px;
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    cursor: pointer;
    align-items: center;
}


.textCrad {
    font-family: 'Hind', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20.713px;
    line-height: 31px;
    text-align: center;
    color: #000000;
    margin-left: 5px;
    margin-right: 5px;
}


.termArea {
    background: #FFCD32;
    border-radius: 0px 0px 23px 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 19.1389px;
    line-height: 29px;
    text-align: center;
    color: #000000;
    height: 66px;
}


.selectioArea {
    display: flex;
    margin-top: 10px;
    align-items: center;
}


.coloImage{
    margin-left: 15px;
    width: 26px;
    height: 26px;
}


.cardImage{
    width: 187px;
    height: 259px;
    margin-top: 10px;
}


@media (max-width:767px) {
    
.workshop1{
    flex-direction: column;
    align-items: center;
}

.commanheading{
    max-width: unset;
}


.aquiArea{
    margin-top: 30px;
    margin-left: 0;
}

}


@media (max-width:480px) {
    

.commanheading{
    font-size: 31.2075px;
    line-height: 45px;
}


.aplicar{
    width: 220px;
    height: 55px;
    font-size: 22.4293px;
    line-height: 27px;
}

.aquiText{
    font-size: 19.44px;
    line-height: 133%;
}



}