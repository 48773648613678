

.home{
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    background: linear-gradient(#141e30, #243b55);
    display: flex;
    min-height: 100%;
}

.abArea{
    display: flex;
    flex-direction: column;

}

.sideNav{
    background: #ffcf10;
    border-right: 1px solid #0003;
    box-shadow: 0 0 32px #0003;
    font-size: 2em;
    font-weight: 300;
    padding-top: 50px;
    width: 100%;
    min-height: 100vh;
}

.tabOne{
    color: #17161F !important;
    padding: 0.75em;
    text-decoration: none;
    transition: all .5s;
    font-size: 20px;
    
}

.tabOne:hover{
    background: #ffffff06;
    color: #17161F !important;
}


.textTop{
    display: flex;
    align-items: flex-start !important;
}

.loginBox {
    width: 400px;
    padding: 40px;
    background: rgba(0, 0, 0, .5);
    box-sizing: border-box;
    box-shadow: 0 15px 25px rgb(0 0 0 / 60%);
    border-radius: 10px;
}


.heading {
    margin: 0 0 30px;
    padding: 0;
    color: #fff;
    text-align: center;
    font-size: 15px;
    font-weight: 700;
}

.mailTitlt {
    color: #03e9f4;
    font-size: 12px;
}

.emailinput {
    width: 100%;
    padding: 6px 0;
    font-size: 16px;
    color: #fff;
    margin-bottom: 30px;
    border: none;
    border-bottom: 1px solid #fff;
    outline: none;
    background: transparent;
}

.submitt {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    color: #03e9f4;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    margin-top: 40px;
    letter-spacing: 4px;
    background: none;
    border: none;
}

.submitt:hover {
    background: #03e9f4;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #03e9f4, 0 0 25px #03e9f4, 0 0 50px #03e9f4, 0 0 100px #03e9f4;
}

.logArea{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
}


.leftHave{
    color: #9ab;
    padding: 0.75em;
    text-decoration: none;
    transition: all .5s;
    font-size: 20px;
position: relative !important;
background: none !important;
}




.leftHave1{
    color: #9ab;
    padding: 0.75em;
    text-decoration: none;
    transition: all .5s;
    font-size: 20px;
position: relative !important;

}


.btn-close{
    color: white !important;
}


@media (max-width:1100px) {

    .sideNav{
        width: 100%;
    }

}

@media (max-width:767px) {

    .home {
        flex-direction: column;
    }

.logArea{
    width: 100%;
}


.sideNav{
    width: 100%;
    padding-top: 0px;
    min-height: 70px;
    margin-bottom: 15vh;
}

.textTop{
    justify-content: flex-start !important;
}


.sideNav{
    height: 70px;
}


.blueBack{
    background-color: #123;
    background-image: linear-gradient(to bottom, #0003, transparent);
}



}



@media (max-width:550px) {


    .loginBox {
        width: 80%;
    }

    .emailinput {
        margin-bottom: 15px;
    }


    .submitt {
        margin-top: 20px;
    }


}