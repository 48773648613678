.formArea {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 50px;
}

.formMian {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 1363px;
  width: 100%;
  max-width: 1299px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 52px;
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  padding-bottom: 30px;
}
.selectt {
  background: #ffffff;
  border: 1px solid #838383;
  border-radius: 22px;
  height: 53px;
  padding-left: 15px;
  padding-right: 10px;
  font-family: "Hind";
  font-style: normal;
  font-weight: 400;
  font-size: 22.5532px;
  line-height: 36px;
  color: #353535;
  width: 92%;
  margin-top: 7px;
  outline: none;
}
.maxArea {
  width: 100%;
  max-width: 1192px;
  margin-left: auto;
  margin-right: auto;
}

.titlePara {
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 49.0565px;
  line-height: 79px;
  color: #000000;
}

.nameArea {
  display: flex;
  width: 100%;
  align-items: center;
}

.namesDiv {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  width: 50%;
}

.namesDiv2 {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  width: 48.5%;
}

.namesDiv1 {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-left: 3.35%;
  width: 50%;
}

.nameHeading {
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20.9422px;
  line-height: 34px;
  color: #353535;
}

.firstName {
  background: #ffffff;
  border: 1px solid #838383;
  border-radius: 22px;
  height: 53px;
  padding-left: 15px;
  padding-right: 10px;
  font-family: "Hind";
  font-style: normal;
  font-weight: 400;
  font-size: 22.5532px;
  line-height: 36px;
  color: #353535;
  width: 92%;
  margin-top: 7px;
  outline: none;
}

.firstName::placeholder {
  color: #2e2525;
}

.changeArea {
  width: 100%;
}

.changeArea2 {
  width: 100%;
}

.callArea {
  display: flex;
  justify-content: space-between;
}

.horizonta {
  margin-top: 11px;
  width: 100%;
  height: 53px;
  padding-left: 15px;
  padding-right: 10px;
  font-family: "Hind";
  font-style: normal;
  font-weight: 400;
  font-size: 22.5532px;
  line-height: 36px;
  color: #353535;
  background: #ffffff;
  border: 1px solid #838383;
  border-radius: 22px;
  outline: none;
}

.horizonta::placeholder {
  color: #353535;
}

.callyaChart {
  display: flex;
  flex-direction: column;
  width: 32%;
  margin-top: 29px;
}

.selectionArea {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

.decompra {
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 28.4386px;
  line-height: 46px;
  color: #353535;
  margin-bottom: 11px;
}

.seclection1 {
  display: flex;
  align-items: center;
}

.checkInput {
  width: 14px;
  height: 14px;
}

.sucral {
  font-family: "Hind";
  font-style: normal;
  font-weight: 400;
  font-size: 21.329px;
  line-height: 34px;
  color: #353535;
  margin-left: 7px;
}

.debas {
  font-family: "Hind";
  font-style: normal;
  font-weight: 400;
  font-size: 20.9929px;
  line-height: 34px;
  color: #353535;
  margin-top: 21px;
}

.regionArea {
  display: flex;
  justify-content: space-between;
}

.regionTitleArea {
  display: flex;
  flex-direction: column;
  margin-top: 19px;
  width: 50%;
}

.regionTitle {
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20.9929px;
  line-height: 34px;
  color: #353535;
}

.selectPut {
  margin-top: 8px;
  font-family: "Hind";
  font-style: normal;
  font-weight: 400;
  font-size: 22.5532px;
  line-height: 36px;
  color: #353535;
  height: 53px;
  width: 92%;
  background: #ffffff;
  max-width: 548px;
}

.selectPut1 {
  margin-top: 8px;
  font-family: "Hind";
  font-style: normal;
  font-weight: 400;
  font-size: 22.5532px;
  line-height: 36px;
  color: #353535;
  height: 53px;
  width: 50%;
  background: #ffffff;
  max-width: 548px;
}

.selectPut1::placeholder {
  color: #353535;
}
.selectPut::placeholder {
  color: #353535;
}

.deentrega {
  margin-top: 18px;
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20.9929px;
  line-height: 34px;
  color: #353535;
}

.deentrega1 {
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20.9929px;
  line-height: 34px;
  color: #353535;
  margin-left: 10px;
}

.textLoweer {
  width: 100%;
  max-width: 1186px;
  height: 183px;
  font-family: "Hind";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 34px;
  color: #353535;
  margin-left: auto;
  margin-right: auto;
  background: #ffffff;
  border: 1px solid #838383;
  border-radius: 22px;
  outline: none;
}

.chalTilt {
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20.9929px;
  line-height: 34px;
  color: #353535;
}

.complitarButton {
  width: 100%;
  height: 52px;
  margin-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffd510;
  border-radius: 22px;
  border: none;
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 33.2928px;
  line-height: 53px;
  color: #353535;
}

.newAddress {
  display: flex;
  flex-direction: column;
}

.selection1Area {
  margin-top: 30px;
  display: flex;
  align-items: center;
}
.w40 {
  width: 40%;
}
@media (max-width: 600px) {
  .formMian {
    box-shadow: none;
  }
  .w40 {
    width: 90%;
  }
  .nameArea {
    flex-direction: column;
  }

  .namesDiv {
    width: 90%;
  }

  .namesDiv1 {
    width: 90%;
    margin-left: 0;
  }

  .namesDiv2 {
    width: 90.5%;
    margin-left: 5%;
  }

  .regionArea {
    flex-direction: column;
    align-items: center;
  }

  .regionTitleArea {
    width: 91%;
  }

  .textLoweer {
    margin-left: 5%;
    width: 84%;
  }

  .complitarButton {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    font-size: 20.2928px;
    margin-top: 40px;
  }

  .callArea {
    flex-direction: column;
    align-items: center;
  }

  .callyaChart {
    width: 83%;
    margin-left: auto;
    margin-right: auto;
  }

  .selectPut1 {
    margin-left: 29px;
    margin-right: auto;
    width: 82%;
  }
}

@media (max-width: 480px) {
  .textLoweer {
    margin-left: 5%;
    width: 90%;
  }

  .titlePara {
    font-size: 38.0565px;
    line-height: 44px;
  }

  .selectPut1 {
    margin-left: 15px;
  }

  .deentrega1 {
    font-size: 20.9929px;
    line-height: 26px;
  }
}
