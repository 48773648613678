.builder {
  display: flex;
  /* max-width: 1440px; */
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 136px;
}

.leftBuilder {
  margin-top: 27px;
  max-width: 276px;
}
.setloa {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 100px;
}
.headingArea {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.activeVV {
  background: #facb33 !important;
}
.heading1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 41.2038px;
  line-height: 62px;
  text-align: center;
  color: #000000;
}

.detail {
  max-width: 202px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13.9561px;
  line-height: 21px;
  text-align: center;
  color: #000000;
}

.detailbox {
  margin-top: 24px;
  padding: 15px;
  border: 1px solid #000000;
  min-height: 419px;
}

.umezawa1 {
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15.0459px;
  line-height: 23px;
  color: #000000;
}

.aplicar {
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24.4753px;
  line-height: 37px;
  text-align: center;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 61px;
  background: #facb33;
  border-radius: 30px;
  margin-top: 17px;
  border: none;
}

.rightBuilder {
  display: flex;
  flex-direction: column;
  margin-left: 3.12%;
  width: inherit;
}

.buttonArea {
  display: flex;
}

.ponsible {
  margin-top: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  min-height: 70px;
  border-radius: 15px;
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24.4753px;
  line-height: 37px;
  text-align: center;
  color: #000000;
  padding: 8px;
}

.cartas {
  display: flex;
  margin-top: 26px;
  justify-content: center;
  align-items: center;
  padding: 8px;
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24.4753px;
  line-height: 37px;
  text-align: center;
  color: #000000;
  border-radius: 15px;
  width: 30%;
  margin-left: 17px;
}

.noEncon {
  display: flex;
  margin-top: 26px;
  justify-content: center;
  align-items: center;
  padding: 8px;
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24.4753px;
  line-height: 37px;
  text-align: center;
  color: #000000;
  /* background: #facb33; */
  border: 1px solid #000000;
  border-radius: 15px;
  width: 30%;
  margin-left: 17px;
}

.lowerBuutons {
  display: flex;
  margin-top: 33px;
}

.cartasBuuton {
  margin-top: 22px;
  padding: 17px;
  width: 32.4%;
  border: 1px solid #000000;
  border-radius: 12px;
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 23.6209px;
  line-height: 35px;
  color: #000000;
}

.totalButton {
  border: 1px solid #000000;
  border-radius: 12px;
  margin-top: 22px;
  padding: 17px;
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 23.6209px;
  line-height: 35px;
  color: #000000;
  margin-left: 15px;
}

.agregar {
  margin-left: 15px;
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24.4753px;
  line-height: 37px;
  text-align: center;
  color: #000000;
  background: #f17531;
  border-radius: 7px;
  margin-top: 22px;
  width: 32.4%;
}

.centerArea {
  margin-top: 27px;
  width: 100%;
  /* max-width: 756px; */
  /* margin-left: auto; */
  /* margin-right: auto; */
}

.centerCard {
  display: flex;
  flex-direction: column;
  margin-top: 33px;
  margin-bottom: 33px;
}

.upperCards {
  display: flex;
  justify-content: space-between;
  margin-bottom: 22px;
  margin-top: 22px;
}

.centerImage {
  width: 133px;
  height: 186px;
}

.umezawa {
  display: flex;
  flex-direction: column;
  margin-left: 10%;
  /* max-width: 16.67%; */
  align-items: center;
  width: 100%;
}

.umeHeading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 21.0085px;
  line-height: 128%;
  text-align: center;
  color: #000000;
}

.umeFrom {
  margin-top: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14.0797px;
  line-height: 21px;
  text-align: center;
  color: #000000;
  min-width: max-content;
}

.kann2 {
  width: 24px;
  height: 29px;
  margin-top: 16px;
}

.numbu {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14.0797px;
  line-height: 21px;
  text-align: center;
  color: #000000;
  padding: 2px 8px;
  /* background: #c9c2c0; */
  margin-top: 10px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
}

.lore {
  display: flex;
  width: 100%;
}

.sinStack {
  margin-right: 5.59%;
  margin-top: auto;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24.4753px;
  line-height: 37px;
  text-align: center;
  color: #000000;
  margin-left: auto;
  margin-right: auto;
}

.num {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 19px;
  color: #000000;
  background: #f69e33;
  border: 1px solid #000000;
  border-radius: 5px;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.idio {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 19px;
  color: #000000;
  background: #f69e33;
  border: 1px solid #000000;
  border-radius: 5px;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.topDesign {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20.0724px;
  line-height: 30px;
  color: #000000;
}

.numberArea {
  display: flex;
  align-items: center;
  justify-content: center;
}

.decremet {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 44px;
  background: #ffcf12;
  border-radius: 18px 0px 0px 18px;
  cursor: pointer;
}

.numberFielkd {
  width: 100px;
  height: 44px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 23px;
  color: #000000;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.increment {
  width: 28px;
  height: 44px;
  background: #ffcf12;
  border-radius: 0px 18px 18px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.dtovk {
  width: 44px;
  height: 44px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 19px;

  background: #facb33;
  border: 1.7127px solid #000000;
  border-radius: 8.56349px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.foils {
  width: 96px;
  height: 37px;
  /* border: 1px solid #000000; */
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
}

.centerKp {
  display: block !important;
}

.togleBorder {
  /* border: 2px solid #facb33 !important; */
  box-shadow: none !important;
  position: relative !important;
  border: none !important;
}

.namfluid {
  /* display: flex !important; */
  justify-content: flex-start !important;
}

.carro1 {
  font-family: "Hind";
  font-style: normal;
  font-weight: 700;
  font-size: 27.1291px;
  line-height: inherit;
  text-align: center;
  color: #000000;
  display: none;
  padding: 7px 19px !important;
  width: auto;
  height: -moz-fit-content;
  height: fit-content;
  background: #ffd44a !important;
}

@media (max-width: 1200px) {
  .ponsible {
    font-size: 19.4753px;
    line-height: 25px;
  }

  .cartas {
    font-size: 19.4753px;
    line-height: 25px;
  }

  .noEncon {
    font-size: 19.4753px;
    line-height: 25px;
  }
  .cartasBuuton {
    font-size: 19.4753px;
    line-height: 25px;
  }

  .totalButton {
    font-size: 19.4753px;
    line-height: 25px;
  }

  .agregar {
    font-size: 19.4753px;
    width: 25.4%;
    line-height: 25px;
  }
}

@media (max-width: 1199px) {
  .carro1 {
    display: block;
  }
  .togleBorder {
    margin-left: 5%;
  }
  .rightBuilder {
    width: 100%;
    margin-left: 0;
  }

  .rightBuilder {
    display: block;
  }

  .builder {
    display: block;
  }
  .umeFrom {
    min-width: auto;
  }
}

@media (max-width: 767px) {
  .ponsible {
    font-size: 13.4753px;
    line-height: 15px;
    min-height: 46px;
  }

  .cartas {
    font-size: 13.4753px;
    line-height: 15px;
    min-height: 46px;
  }

  .noEncon {
    font-size: 13.4753px;
    line-height: 15px;
    min-height: 46px;
  }

  .cartasBuuton {
    font-size: 13.4753px;
    line-height: 15px;
  }

  .totalButton {
    font-size: 13.4753px;
    line-height: 15px;
  }

  .agregar {
    font-size: 13.4753px;
    line-height: 15px;
    width: 25.4%;
  }
  .carro1 {
    font-size: 21.1291px;
    line-height: inherit;
    text-align: center;
  }
}

@media (max-width: 560px) {
  .upperCards {
    flex-direction: column;
  }

  .sinStack {
    margin-top: 15px;
    font-size: 22.4753px;
    text-align: left;
  }
}

@media (max-width: 480px) {
  .buttonArea {
    flex-direction: column;
  }

  .cartas {
    margin-left: 0;
    margin-top: 9px;
  }

  .noEncon {
    margin-left: 0;
    margin-top: 9px;
  }

  .lowerBuutons {
    flex-direction: column;
  }

  .cartasBuuton {
    width: 126px;
  }

  .totalButton {
    width: 126px;
    margin-left: 0;
    padding: 8px;
  }

  .agregar {
    width: 126px;
    height: fit-content;
    margin-left: 0;
    padding: 8px;
  }
}
