.secretArea {
  width: 100%;
  max-width: 1360px;
  display: flex;
  align-items: flex-end;
  padding-bottom: 19px;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  align-self: flex-end;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
}
.catt{
  position: absolute;
  right: -16px;
  width: 127px;
  height: 179px;
  top: -75px;
}
.catt2{
  position: absolute;
  left: -25px;
  width: 127px;
  height: 179px;
  top: -75px;
}
.catt3{
  position: absolute;
  right: 33px;
  width: 127px;
  height: 179px;
  top: -153px;
}
.secra {
  background: rgba(255, 205, 50, 0.21);
  min-height: 179px;
  display: flex;
  align-items: flex-end;
  margin-bottom: 37px;
}

.compra {
  position: relative;
  font-family: "Tinos";
  font-style: normal;
  font-weight: 700;
  font-size: 31.2535px;
  line-height: 36px;
  color: #17161f;
  margin-left: 10px;
  margin-right: 10px;
}

.lair {
  width: 17.5%;
  height: 143px;
  position: relative;
  margin-left: 10px;
  margin-right: 10px;
}

.comobasket {
  width: 76px;
  height: 68px;
  margin-bottom: 18px;
}

.como {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.venidos {
  background: linear-gradient(90deg, #fff000 0%, #f47721 100%);
  width: 100%;
  min-height: 638px;
}

.centervanidos {
  display: flex;
  width: 100%;
  max-width: 1440px;
  align-items: center;
  height: 100%;
  justify-content: space-around;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.computerImageArea {
  width: 33.26%;
  height: 361px;
  position: relative;
}

.comput {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.comflo {
  width: 30%;
  height: 117px;
  position: absolute;
  left: 13.2%;
  top: 115px;
  object-fit: contain;
}

.comprara {
  font-family: "Tinos";
  font-style: normal;
  font-weight: 700;
  font-size: 41.4109px;
  line-height: 48px;
  color: #17161f;
  position: absolute;
  right: 15.9%;
  top: 160px;
}

.catRea {
  width: 45.4%;
  display: flex;
  flex-direction: column;
  padding-top: 62px;
  align-items: center;
  justify-content: space-between;
}

.bien {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 70.1313px;
  line-height: 105px;
  color: #17161f;
}

.nuestra {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 30.0053px;
  line-height: 41px;
  text-align: center;
  color: #17161f;
  max-width: 654px;
}

.cato {
  width: 81.8%;
  height: 348px;
  max-width: 535px;
}

.mejorARea {
  margin-top: 134px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}

.mejorHeading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 62.1163px;
  line-height: 93px;
  color: #17161f;
  text-align: center;
}

.cardsArea {
  width: 100%;
  max-width: 1055px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  position: relative;
}

.card1 {
  display: flex;
  flex-direction: column;
  width: 156px;
  margin-top: 25px;
  /* margin-top: 2px; */
}

.cardImag {
  width: 156px;
  height: 214px;
}

.cardText {
  margin-top: 16px;
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 17.4025px;
  line-height: 140%;
  text-align: center;
  color: #17161f;
}

.ultemoARea {
  margin-top: 86px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}

.masARea {
  margin-top: 86px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 147px;
}

.navBack {
  width: 100%;
  background: #17161f;
}

.comflonav {
  position: absolute;
  width: 16.25%;
  height: 190px;
  top: -100px;
  left: 31px;
}

.comobasket {
  display: block;
}

@media (max-width: 1120px) {
  .comflonav {
    width: 13.25%;
    height: 162px;
    top: -86px;
  }

  .compra {
    font-size: 25.2535px;
    line-height: 29px;
  }

  .computerImageArea {
    width: 40.26%;
  }
}

@media (max-width: 992px) {
  .comflonav {
    display: none;
  }
}

@media (max-width: 650px) {
  .secretArea {
    flex-direction: column;
    align-items: center;
  }

  .compra {
    margin-top: 20px;
  }

  .lair {
    margin-top: 20px;
    width: 36.5%;
    height: 121px;
  }

  .como {
    margin-top: 20px;
  }

  .comobasket {
    margin-bottom: -20px;
  }
}

@media (max-width: 920px) {
  .centervanidos {
    flex-direction: column;
  }

  .computerImageArea {
    width: 57.26%;
    margin-top: 20px;
  }

  .catRea {
    width: 96.4%;
  }
}

@media (max-width: 670px) {
  .computerImageArea {
    width: 80%;
  }
}

@media (max-width: 530px) {
  .comflo {
    width: 21%;
    height: 96px;
    position: absolute;
    left: 13.2%;
    top: 110px;
  }

  .comprara {
    font-size: 25.4109px;
    line-height: 30px;
    top: 145px;
  }

  .cato {
    width: 58.8%;
    height: 208px;
    max-width: 535px;
  }

  .nuestra {
    font-size: 20.0053px;
    line-height: 24px;
  }

  .bien {
    font-size: 33.1313px;
    line-height: 40px;
  }
}

@media (max-width: 670px) {
  .mejorHeading {
    font-size: 45.1163px;
    line-height: 50px;
  }
}

@media (max-width: 450px) {
  .mejorARea {
    margin-top: 50px;
  }

  .ultemoARea {
    margin-top: 50px;
  }

  .masARea {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .mejorHeading {
    font-size: 32.1163px;
    line-height: 38px;
  }

  .cardsArea {
    flex-direction: column;
    align-items: center;
  }
}
