.rgister {
  display: flex;
  width: 100%;
  min-height: 100vh;
}

.registerLeft {
  width: 61.67%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 69px 15px 30px 15px;
  height: 882px;
}

.registTitle {
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 69.7491px;
  line-height: 112px;
  color: #000000;
}

.poder {
  font-family: "Hind";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 128.1%;
  text-align: center;
  text-align: center;
  max-width: 500px;
}

.NmaeArea {
  display: flex;
  width: 81.53%;
  justify-content: space-between;
  max-width: 724px;
}

.nombre {
  background: #d9d9d9;
  border-radius: 17px;
  max-width: 349px;
  width: 48.2%;
  height: 46px;
  padding-left: 16px;
  padding-right: 10px;
  font-family: "Hind";
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 128.1%;
  color: black;
  outline: none;
  margin-top: 27px;
  border: none;
}

.nombre::placeholder {
  color: black;
}

.correo {
  max-width: 724px;
  width: 81.53%;
  background: #d9d9d9;
  border-radius: 17px;
  height: 46px;
  padding-left: 16px;
  padding-right: 10px;
  font-family: "Hind";
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 128.1%;
  color: black;
  outline: none;
  margin-top: 27px;
  border: none;
}

.correo::placeholder {
  color: black;
}

.slectionArea {
  display: flex;
  margin-top: 22px;
  align-items: center;
}

.checkIn {
  background: #cccccc !important;
  border: 1px solid #000000 !important;
  border-radius: 2px !important;
  width: 19px;
  height: 19px;
}

.acepto {
  margin-left: 25px;
  font-family: "Hind";
  font-style: normal;
  font-weight: 500;
  font-size: 23.7275px;
  line-height: 128.1%;
  color: #cccccc;
}

.selects {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.crear {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 51.432px;
  line-height: 82px;
  text-align: center;
  color: #000000;
  background: #ffcd32;
  border-radius: 29px;
  max-width: 724px;
  width: 81.53%;
  height: 68px;
  border: none;
  margin-top: 42px;
}

.registerRight {
  display: flex;
  flex-direction: column;
  width: 38.33%;
  align-items: center;
  padding: 201px 15px 0 15px;
  background: #ffcd32;
  position: relative;
}

.rightHeading {
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 52.6262px;
  line-height: 84px;
  color: #000000;
}

.sesion {
  margin-top: 110px;
  width: 367px;
  height: 82px;
  background: #040404;
  border-radius: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 39.9811px;
  line-height: 64px;
  color: #ffffff;
}

.yaun {
  font-family: "Hind";
  font-style: normal;
  font-weight: 400;
  font-size: 23.6926px;
  line-height: 38px;
  color: #030303;
  margin-top: 20px;
  cursor: pointer;
}

.RregisterCat {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 343px;
  height: 336px;
}

@media (max-width: 1250px) {
  .rgister {
    flex-direction: column-reverse;
  }

  .registerRight {
    width: 100%;
    padding-top: 65px;
    padding-bottom: 30px;
  }

  .registerLeft {
    width: 100%;
    height: unset;
    padding-bottom: 30px;
  }

  .RregisterCat {
    width: 330px;
    height: 321px;
  }
}

@media (max-width: 992px) {
  .RregisterCat {
    width: 258px;
    height: 232px;
  }

  .registTitle {
    font-size: 37.6262px;
    line-height: 59px;
  }

  .rightHeading {
    font-size: 37.6262px;
    line-height: 59px;
  }

  .sesion {
    margin-top: 90px;
    width: 263px;
    height: 62px;
    font-size: 27.9811px;
  }

  .yaun {
    font-size: 18.6926px;
    line-height: 29px;
    margin-top: 17px;
  }

  .crear {
    width: 53.53%;
    height: 59px;
    font-size: 38.432px;
  }
}

@media (max-width: 767px) {
  .registTitle {
    font-size: 36.7491px;
    line-height: 51px;
  }

  .poder {
    font-size: 20px;
    line-height: 128.1%;
  }

  .nombre {
    height: 39px;
    font-size: 18px;
  }

  .correo {
    height: 39px;
    font-size: 18px;
  }

  .slectionArea {
    margin-top: 10px;
  }

  .acepto {
    font-size: 17.7275px;
    line-height: 128.1%;
  }

  .crear {
    font-size: 36.432px;
    line-height: 52px;
    height: 57px;
    width: 357px;
  }

  .registerRight {
    height: 420px;
  }

  .sesion {
    margin-top: 45px;
  }
}

@media (max-width: 480px) {
  .registTitle {
    font-size: 28.7491px;
    line-height: 48px;
  }

  .poder {
    font-size: 18px;
    line-height: 128.1%;
  }

  .NmaeArea {
    width: 90%;
  }

  .correo {
    width: 90%;
  }

  .crear {
    font-size: 28.432px;
    line-height: 40px;
    height: 48px;
    width: 250px;
  }

  .acepto {
    font-size: 11.7275px;
    line-height: 128.1%;
    margin-left: 10px;
  }

  .rightHeading {
    font-size: 28.6262px;
    line-height: 50px;
  }

  .sesion {
    width: 197px;
    height: 44px;
    font-size: 19.9811px;
  }

  .yaun {
    font-size: 16.6926px;
    line-height: 24px;
  }

  .RregisterCat {
    width: 185px;
    height: 170px;
  }

  .registerRight {
    height: 376px;
  }
}
