.margLower{
    border-bottom: 1px solid black !important;
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 50px;
    min-height: 500px;
}

.topRow{
    font-family: 'Asap', sans-serif;
font-style: normal;
font-weight: 500;
font-size: 20.0724px;
line-height: 30px;
text-align: center;
color: #000000;
}


.cartaArea {
    display: flex;
    align-items: center;
    justify-content: center;
}


.confi{
    cursor: pointer;
    margin-left: 5px;
    border-radius: 5px;
}


@media (max-width:480px) {

    .margLower{
        min-height: unset;
    }
    
}