.cuultOUTER {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  flex-direction: column;
  margin-bottom: 52px;
}

.cuult {
  display: flex;
  width: 100%;
  max-width: 1220px;
  margin-top: 107px;
  margin-left: auto;
  margin-right: auto;
}

.imageArea {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.Lair2 {
  width: 387px;
  height: 388px;
  margin-left: 40px;
}

.buttonsArea {
  display: flex;
  flex-direction: column;
  margin-left: 58px;
}

.cultHead {
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24.1077px;
  line-height: 30px;
  color: #000000;
}

.amOUNT {
  font-family: "Hind";
  font-style: normal;
  font-weight: 500;
  font-size: 24.1077px;
  line-height: 39px;
  color: #000000;
  margin-top: 6px;
}

.una {
  font-family: "Hind";
  font-style: normal;
  font-weight: 400;
  font-size: 16.4371px;
  line-height: 26px;
  color: #000000;
}

.diffButtons {
  display: flex;
  flex-wrap: wrap;
  max-width: 574px;
  justify-content: flex-start;
}

.abiet {
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16.4371px;
  line-height: 26px;
  color: #000000;
  margin-top: 15px;
  background: #ffd013;
  box-shadow: 3px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  min-width: 132px;
  height: 40px;
  border: none;
  cursor: pointer;
  margin-left: 5px;
  width: auto;
  padding: 0px 12px;
}

.cerrad {
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16.4371px;
  line-height: 26px;
  color: #000000;
  background: rgba(193, 176, 146, 0.44);
  border: 1px solid rgba(193, 176, 146, 0.44);
  box-shadow: 3px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin-top: 15px;
  margin-left: 5px;
  min-width: 133px;
  width: auto;
  padding: 0px 12px;
  height: 40px;
}

.preButtons {
  display: flex;
}

.ahora {
  margin-top: 21px;
  margin-left: 26px;
  background: #ffd013;
  border-radius: 5px;
  width: 192px;
  height: 40px;
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16.4371px;
  line-height: 26px;
  color: #000000;
  border: none;
}

.estaBundle {
  font-family: "Hind";
  font-style: normal;
  font-weight: 400;
  font-size: 16.4371px;
  line-height: 121.6%;
  color: #000000;
  margin-top: 30px;
  max-width: 574px;
}

.fecha {
  font-family: "Hind";
  font-style: normal;
  font-weight: 500;
  font-size: 18.44px;
  line-height: 121.6%;
  color: #e9252a;
  margin-top: 9px;
}

.dala {
  font-family: "Hind";
  font-style: normal;
  font-weight: 500;
  font-size: 21.44px;
  line-height: 121.6%;
  color: #c4b191;
  margin-top: 15px;
}

.conten {
  margin-top: 32px;
  width: 384px;
  min-height: 194px;
  padding: 20px;
  border: 1px solid #000000;
  font-family: "Hind";
  font-style: normal;
  font-weight: 400;
  font-size: 19.3592px;
  line-height: 121.6%;
  color: #000000;
}

.sliderArea {
  display: flex;
  align-items: center;
}

.numInputs {
  display: flex;
  margin-top: 21px;
}

.minus {
  background: #ffd013;
  border: 1px solid #000000;
  border-radius: 5px 0px 0px 5px;
  width: 24px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.plus {
  background: #ffd013;
  border: 1px solid #000000;
  border-radius: 0px 5px 5px 0px;
  width: 24px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.colorfullInput {
  width: 59px;
  height: 40px;
  border: 1px solid #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14.0991px;
  line-height: 21px;
  color: #000000;
}

.preciew {
  width: 100%;
  height: 500px;
}

@media (max-width: 1200px) {
  .Lair2 {
    width: 288px;
    height: 297px;
    margin-left: 20px;
  }

  .conten {
    width: 288px;
  }

  .buttonsArea {
    margin-left: 25px;
  }
}

@media (max-width: 992px) {
  .cuult {
    flex-direction: column;
  }

  .imageArea {
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }

  .buttonsArea {
    margin-top: 90px;
    margin-left: 0;
    align-items: center;
  }

  .conten {
    margin-left: 30px;
  }

  .preciew {
    height: 290px;
  }
}

@media (max-width: 767px) {
  .imageArea {
    flex-direction: column;
  }

  .cuult {
    margin-top: 80px;
  }

  .conten {
    margin-left: 75px;
    margin-top: 62px;
  }

  .buttonsArea {
    margin-top: 40px;
  }

  .preciew {
    height: 234px;
  }
}

@media (max-width: 450px) {
  .Lair2 {
    width: 243px;
    height: 249px;
  }

  .conten {
    width: 253px;
  }

  .preciew {
    height: 210px;
  }
}
