.cartInner {
  display: flex;
  flex-direction: column;
  max-width: 1440px;
}

.top {
  display: flex;
  margin-top: 32px;
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 50px;
  justify-content: center;
}

.leftcarro {
  background: rgba(255, 207, 16, 0.99);
  border: 1px solid #000000;
  border-radius: 12px 0px 0px 12px;
  width: 46.2%;
  min-height: 67px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24.045px;
  line-height: 36px;
  color: #000000;
}

.rightCarro {
  background: rgba(255, 255, 255, 0.4);
  border: 1px solid #000000;
  border-radius: 0px 12px 12px 0px;
  width: 46.2%;
  min-height: 67px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24.045px;
  line-height: 36px;
  color: #000000;
}

.tableee {
  width: 100%;
  max-width: 1360px;
  margin-left: auto;
  margin-right: auto;
}

.cartaArea {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cartAtext {
  max-width: 109px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 17.3961px;
  line-height: 26px;
  text-align: center;
  color: #007bff;
}

.bander1 {
  width: 72px;
  height: 98px;
}

.num {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 12.7537px;
  line-height: 24px;
  color: #000000;
  background: #f17531;
  border: 1px solid #000000;
  border-radius: 5px;
  width: 36px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.idio {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 12.7537px;
  line-height: 19px;
  color: #000000;
  background: #f69e33;
  border: 1px solid #000000;
  border-radius: 5px;
  width: 36px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.pricArea {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: 59px;
}

.totalPargo {
  font-family: "Hind", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 28.7704px;
  line-height: 43px;
  letter-spacing: -0.08em;
  color: #000000;
}

.dollar {
  font-weight: 700;
  font-size: 32.77px;
  line-height: 49px;
}

.dicountArea {
  display: flex;
  flex-direction: column;
}

.diucouynPrice {
  font-family: "Hind", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 28.7704px;
  line-height: 43px;
  letter-spacing: -0.08em;
  margin-left: 10px;
}

.radioInput {
  width: 10px;
  height: 10px;
}

.radioArea {
  display: flex;
  align-items: center;
}

.margLower {
  border-bottom: 1px solid black !important;
}

.priceDetail {
  font-family: "Hind", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14.7197px;
  line-height: 22px;
  letter-spacing: -0.08em;
  color: #000000;
}

.comprara {
  margin-top: 14px;
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 25.5318px;
  line-height: 38px;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 135px;
  height: 66px;
  background: #fdcf41;
  border: 1px solid #000000;
  border-radius: 20px;
  margin-left: auto;
}

.anadir {
  margin-left: auto;
  margin-top: 14px;
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 25.5318px;
  line-height: 38px;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 135px;
  height: 66px;
  background: #fdcf41;
  border: 1px solid #000000;
  border-radius: 20px;
}

.topRow {
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20.0724px;
  line-height: 30px;
  text-align: center;
  color: #000000;
}
.sunii {
  max-width: 90%;
  width: 100%;
  text-align: center;
  font-family: "Hind", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 25.5318px;
  line-height: 38px;
  color: #000000;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 1070px) {
  .leftcarro {
    font-size: 19.045px;
  }

  .rightCarro {
    font-size: 19.045px;
  }
}

@media (max-width: 767px) {
  .top {
    flex-direction: column;
    align-items: center;
  }

  .leftcarro {
    width: 90%;
    border-radius: 10px;
    text-align: center;
  }

  .rightCarro {
    width: 90%;
    border-radius: 10px;
    text-align: center;
  }

  .totalPargo {
    font-size: 17.7704px;
    text-align: right;
  }

  .dollar {
    font-size: 19.77px;
    line-height: 34px;
  }

  .diucouynPrice {
    font-size: 17.7704px;
    line-height: 25px;
  }

  .dicountArea {
    align-items: end;
  }

  .priceDetail {
    font-size: 12.7197px;
    line-height: 22px;
  }

  .comprara {
    font-size: 20.5318px;
    line-height: 25px;
    width: 108px;
    height: 45px;
    border-radius: 13px;
  }

  .anadir {
    font-size: 20.5318px;
    line-height: 25px;
    width: 108px;
    height: 45px;
    border-radius: 13px;
  }

  .sunii {
    font-size: 18.5318px;
    line-height: 28px;
    margin-top: 7px;
    width: 95%;
  }
}
