.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 34px;
  padding-right: 18px;
  margin-bottom: 50px;
}
.basket.clicked {
  background-color: #ff0000; /* or whatever color you want on click */
}
.new_cart {
  margin-top: 5%;
  right: 10px;
  width: 200px;
  position: absolute;
}
.linee {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.ccbutton {
  background-color: #ffcf10;
  height: 40px;
  width: 100%;
  border-radius: 20px;
  outline: none;
  border: none;
  color: black;
  font-size: 25px;
  font-family: "asap", sans-serif;
  font-weight: bold;
  cursor: pointer;
}
.cardMian {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.toprad {
  display: flex;
  flex-direction: column;
  padding-top: 24px;
}

.title {
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 36.8637px;
  line-height: 59px;
  color: #17161f;
  margin-left: 14px;
}

.stockDesi {
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20.0724px;
  line-height: 30px;
  color: #000000;
}

.description {
  display: flex;
  margin-top: 22px;
  margin-left: 7px;
}

.CardViewImage {
  width: 415px;
  height: 581px;
}

.descriptionArea {
  display: flex;
  flex-direction: column;
  margin-left: 5.6%;
}

.ediicio {
  font-family: "Hind";
  font-style: normal;
  font-weight: 600;
  font-size: 15.262px;
  line-height: 24px;
}

.tipu {
  font-family: "Hind";
  font-style: normal;
  font-weight: 600;
  font-size: 15.262px;
  line-height: 24px;
  margin-top: 19px;
  display: flex;
  align-items: center;
}

.img1 {
  width: 26px;
  height: 26px;
  margin-left: 2px;
}

.nuber {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14.0797px;
  line-height: 21px;
  text-align: center;
  background: #c9c2c0;
  margin-left: 4px;
  border-radius: 50%;
  padding: 2px 8px;
}

.kann {
  width: 35px;
  height: 40px;
  margin-left: 5px;
}

.dsescip1 {
  white-space: pre-wrap;
  max-width: 670px;
  font-family: "Hind";
  font-style: normal;
  font-weight: 400;
  font-size: 14.9732px;
  line-height: 24px;
  color: #17161f;
}

.tableArea {
  width: 100%;
  position: relative;
  padding-top: 30px;
}

.basket {
  width: 37px;
  height: 37px;
  border: 1px solid #000000;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: 14px;
  cursor: pointer;
}

.shopp {
  width: 21px;
  height: 21px;
}

.num {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  background: #f17531;
  border: 1px solid #000000;
  border-radius: 5px;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.idio {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 19px;
  color: #000000;
  background: #f69e33;
  border: 1px solid #000000;
  border-radius: 5px;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.foilss {
  width: 113px;
  height: 39px;
}

.stok {
  width: 44px;
  height: 44px;
  background: #f9c934;
  border: 0.970529px solid #000000;
  border-radius: 4.85265px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Hind";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #000000;
  margin-left: auto;
  margin-right: auto;
}

.numberArea {
  display: flex;
  align-items: center;
  justify-content: center;
}

.decremet {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 21px;
  height: 44.14px;
  background: #ffcf12;
  border-radius: 18px 0px 0px 18px;
  cursor: pointer;
}

.numberFielkd {
  width: 100px;
  height: 44px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15.07px;
  line-height: 23px;
  color: #000000;
  border: 0.5px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.increment {
  width: 21px;
  height: 44.14px;
  background: #ffcf12;
  border-radius: 0px 18px 18px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.viewCat {
  position: absolute;
  width: 104px;
  height: 136px;
  top: -90px;
  right: 48px;
}

@media (max-width: 1200px) {
  .CardViewImage {
    width: 300px;
    height: 412px;
  }
}
@media (max-width: 1000px) {
  .new_cart {
    display: none;
  }
}

@media (max-width: 767px) {
  .CardViewImage {
    width: 260px;
    height: 339px;
  }

  .description {
    flex-direction: column;
    align-items: center;
  }

  .descriptionArea {
    margin-top: 20px;
  }

  .viewCat {
    display: none;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 26.8637px;
    line-height: 31px;
    text-align: center;
  }

  .CardViewImage {
    width: 213px;
    height: 266px;
  }
}
