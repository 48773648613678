.order {
    width: 100%;
    padding: 15px;
}

.oderInner {
    width: 100%;
    max-width: 1023px;
    min-height: 900px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 11px solid #1EB683;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
}


.OrderSuccess {
    margin-top: 90px;
    width: 215px;
    height: 215px;
}


.pedido {
    font-family: 'Asap', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 50.5917px;
    line-height: 81px;
    color: #1EB683;
    margin-top: 33px;
}


.pedDetail {
    font-family: 'Hind';
    font-style: normal;
    font-weight: 600;
    font-size: 33.3114px;
    line-height: 53px;
    text-align: center;
    color: #000000;
    margin-top: 30px;
    max-width: 613px;
}

@media (max-width:1440px) {


    .order {
        max-height: 100% !important;
    }


    .OrderSuccess {
        margin-top: 30px;
        width: 150px;
        height: 150px;

    }


    .oderInner {
        height: 520px !important;
        border: 8px solid #1EB683;
        min-height: unset;
    }

    .pedido {
        font-size: 35.5917px;
        line-height: 41px;
        margin-top: 20px;
    }

    .pedDetail {
        font-size: 25.3114px;
        line-height: 32px;
        margin-top: 25px;
    }

}

@media (max-width:680px) {


    .oderInner {
        height: 400px !important;
        border: 4px solid #1EB683;
        min-height: unset;
    }


    .OrderSuccess {
        margin-top: 20px;
        width: 100px;
        height: 100px;
    }

    .pedido {
        font-size: 30.5917px;
        line-height: 32px;
        margin-top: 20px;
    }


    .pedDetail {
        font-size: 20.3114px;
        line-height: 28px;
        margin-top: 20px;
    }

}



@media (max-width:680px) {


    .oderInner {
        height: 400px !important;
        border: 4px solid #1EB683;
        min-height: unset;
    }


    .OrderSuccess {
        margin-top: 20px;
        width: 100px;
        height: 100px;
    }

    .pedido {
        font-size: 21.5917px;
        line-height: 32px;
        margin-top: 20px;
    }


    .pedDetail {
        font-size: 15.3114px;
        line-height: 23px;
        margin-top: 20px;
    
    }

}