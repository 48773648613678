.seret {
    display: flex;
    flex-direction: column;
}


.lairImage {
    width: 100%;
    height: 376px;
}


.laiSec {
    width: 100%;
    height: 180px;
    background: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 21px;
}

.yellowLair {
    width: 267px;
    height: 153px;
}


.bien {
    display: flex;
    flex-direction: column;
    margin-top: 44px;
}


.bienText {
    display: flex;
    flex-direction: column;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
}


.bienText1 {
    display: flex;
    flex-direction: column;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 53px;
    margin-bottom: 100px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
}

.lainText {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.line {
    width: 301px;
    border: 2px solid #615F5F;
}


.lainHeading {
    font-family: 'Asap', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 46.2867px;
    line-height: 57px;
    color: #000000;
}



.lorem {
    font-family: 'Hind';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 115.1%;
    max-width: 1194px;
    margin-top: 26px;
}


.blackLair {
    margin-top: 36px;
    width: 100%;
    height: 374px;
}

.lainHeading1 {
    font-family: 'MADE Kenfolg v2';
    font-style: normal;
    font-weight: 400;
    font-size: 58.8862px;
    line-height: 73px;
    color: #000000;
}

.cardsArea {
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 56px;
}

.card1 {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;

}

.card2 {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
}



.edi1 {
    width: 512px;
    height: 516px;
}


.secreArea {
    display: flex;
    background: #CBCCCB;
    min-height: 319px;
    flex-direction: column;
    padding-left: 5.7%;
    justify-content: center;
    width: 59.15%;
    max-width: 834px;
    padding-right: 10px;
    padding-top: 33px;
    padding-bottom: 25px;
}

.por {
    font-family: 'Asap', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 36.3371px;
    line-height: 45px;
    color: #000000;
}


.twnty {
    font-family: 'Hind';
    font-style: normal;
    font-weight: 700;
    font-size: 23.9494px;
    line-height: 38px;
    color: #000000;
    margin-top: 5px;
}

.desta {
    font-family: 'Hind';
    font-style: normal;
    font-weight: 400;
    font-size: 23.9494px;
    line-height: 38px;
    color: #000000;
    max-width: 622px;
}


.fishArea {
    display: flex;
    margin-top: 30px;
    align-items: center;
    justify-content: space-between;
    padding-right: 7%;
}


.leftFish {
    display: flex;
}



.ant1 {
    width: 85px;
    height: 101px;
}

.fishBlack {
    display: flex;
    width: 190px;
    flex-wrap: wrap;
    margin-left: 20px;
}

.black {
    width: 38px;
    height: 38px;
}

.infor {
    font-family: 'Asap', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 23.9494px;
    line-height: 38px;
    color: #FFFFFF;
    width: 288px;
    height: 47px;
    background: #000000;
    border-radius: 16px;
    text-align: center;
}



@media (max-width:1250px) {


    .edi1 {
        width: 360px;
        height: 448px;
    }

    .secreArea {
        padding-left: 1.7%;
        width: 65.15%;
    }

    .por {
        font-size: 32.3371px;
        line-height: 37px;
    }

    .twnty {
        font-size: 18.9494px;
        line-height: 25px;
    }

    .desta {
        font-size: 18.9494px;
        line-height: 24px;
    }

    .fishArea {
        padding-right: 2%;
    }

    .ant {
        width: 74px;
        height: 89px;
    }

    .black {
        width: 32px;
        height: 32px;
    }

    .infor {
        font-size: 18.9494px;
        line-height: 25px;
        width: 193px;
        height: 41px;
    }



}





@media (max-width:1230px) {
    .lainText {
        justify-content: space-around;
    }

    .line {
        width: 180px;
    }

    .lainHeading {
        font-size: 40.2867px;
        line-height: 57px;
    }

    .lorem {
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
}


@media (max-width:992px) {

    .lairImage {
        height: 290px;
    }

    .lainHeading1 {
        font-size: 41.8862px;
        line-height: 48px;
    }


    .blackLair {
        height: 290px;
    }

    .laiSec {
        height: 137px;
    }

    .yellowLair {
        width: 190px;
        height: 108px;
    }


}


@media (max-width:900px) {

    .bienText1 {
        margin-bottom: 15px;
    }

    .card1 {
        flex-direction: column;
        margin-top: 40px;
    }

    .card2 {
        flex-direction: column;
        margin-top: 30px;
    }


    .secreArea {
        width: 96%;
        margin-top: 20px;
    }


}



@media (max-width:820px) {

    .line {
        width: 100px;
    }

    .lainHeading {
        font-size: 30.2867px;
        line-height: 35px;
    }

    .lorem {
        font-size: 20px;
        line-height: 115.1%;
    }
}




@media (max-width:767px) {

    .lairImage {
        height: 234px;
    }

    .blackLair {
        height: 234px;
    }

    .laiSec {
        height: 103px;
    }

    .yellowLair {
        width: 140px;
        height: 84px;
    }

    .lainHeading1 {
        font-size: 35.8862px;
        line-height: 43px;
    }


}


@media (max-width:550px) {

    .line {
        display: none;
    }

    .lorem {
        width: 90%;
    }


    .fishArea {
        flex-direction: column;
        margin-top: 20px;
    }

    .infor {
        margin-top: 12px;
    }

    .edi1 {
        width: 303px;
        height: 366px;
    }
}


@media (max-width:450px) {

    .lairImage {
        height: 210px;
    }

    .blackLair {
        height: 210px;
    }

    .laiSec {
        height: 93px;
    }

    .yellowLair {
        width: 119px;
        height: 73px;
    }

    .edi1 {
        width: 248px;
        height: 296px;
    }

    .secreArea {
        padding-top: 10px;
    }

    .por {
        font-size: 26.3371px;
        line-height: 35px;
    }

    .twnty {
        font-size: 15.9494px;
        line-height: 20px;
    }


    .desta {
        font-size: 13.9494px;
        line-height: 20px;
    }

    .ant1 {
        width: 65px;
        height: 88px;
    }


    .black {
        width: 24px;
        height: 24px;
    }


    .infor {
        margin-top: 41px;
        font-size: 15.9494px;
        line-height: 25px;
        width: 151px;
        height: 35px;
    }

}