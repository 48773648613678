body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* Add these styles to your CSS */
.react-autosuggest__container {
  position: relative;
}

select {
  border: 0.5px solid black;
  border-radius: 5px;
}
.dfl{
  display: flex;
}
.react-autosuggest__input {
  /* width: 240px;
  height: 30px;
  padding: 10px 20px;
 
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
  display: block; */
  width: 100%;
  font-family: Helvetica, sans-serif;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 1rem;
  -webkit-box-shadow: inset 0 0 4px #000;
  -moz-box-shadow: inset 0 0 4px #000;
  box-shadow: inset 0 0 4px #000;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 240px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
  max-height: 300px;
  overflow: auto;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  max-height: 300px;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}
#setSelect option {
  width: 30px !important;
}
@media (max-width: 540px) {
  .react-autosuggest__input {
    font-weight: 600 !important;
  }
}
.dfg {
  display: none;
}
.dfg1 {
  display: flex;
}
.newB {
  display: none !important;
}
@media (max-width: 1199px) {
  .newB {
    display: flex !important;
  }
}
@media (max-width: 720px) {
  .dfg {
    display: flex;
  }
  .dfg1 {
    display: none;
  }
}
