

.termArea1{
    background: #FFCD32;
    border-radius: 0px 0px 23px 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    font-family: 'Asap', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 19.1389px;
    line-height: 29px;
    text-align: center;
    color: #000000;
    height: 66px;
    width: 100%;
}

.termArea{
    background: #999999;
    border-radius: 0px 0px 23px 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    font-family: 'Asap', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 19.1389px;
    line-height: 29px;
    text-align: center;
    color: #000000;
    height: 66px;
    width: 100%;
}