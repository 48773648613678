.titl {
  font-family: "Tinos" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 40.4457px !important;
  line-height: 47px !important;
  color: #ffffff !important;
  margin-left: 20.6%;
  margin-bottom: -16px;
}
.comobasket {
  width: 37px;
  height: 30px;
  color: white;
}
.backColo {
  background: #17161f;
}

.centermain {
  display: flex;
  justify-content: flex-end !important;
}
.mainNav {
  height: 102px;
  width: 100%;
}

.inputtext {
  width: 87% !important;
  height: 54px;
  background: #d9d9d9;
  border-radius: 15px;
  color: #040707;
  padding: 11px 10px 14px 24px;
  font-family: "Tinos";
  font-style: normal;
  font-weight: 400;
  font-size: 25.4713px;
  line-height: 29px;
  outline: none;
}

.stillOff {
  display: none !important;
}
.searcharaea {
  width: 254px;
  /* margin-left: 3.7%; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.searchIcon {
  font-size: 40px;
  fill: #ffffff;
  cursor: pointer;
}

.fiftyArea {
  width: 53%;
  display: flex;
  justify-content: space-between;
}

.loginARE {
  display: flex;
  align-items: center;
  margin-left: 5.4%;
}

.loginARE1 {
  display: none;
  align-items: center;
  margin-left: 5.4%;
  margin-top: 20px;
  justify-content: center;
}

.login1 {
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20.18px;
  line-height: 25px;
  color: black;
}

.login {
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 25.18px;
  line-height: 30px;
  color: #ffffff;
}

.verticalAlign {
  height: 52.5px;
  border: 2px solid #ffffff;
  margin-left: 10px;
  border-radius: 6px;
}

.verticalAlign1 {
  height: 35.5px;
  border: 2px solid black;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 2px;
}

.signuptext {
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 25.18px;
  line-height: 30px;
  color: #ffffff;
  margin-left: 10px;
  margin-right: 53px;
}

.signuptext1 {
  font-family: "Tinos";
  font-style: normal;
  font-weight: 700;
  font-size: 20.18px;
  line-height: 25px;
  color: black;
}

.navbar-toggler {
  background: white !important;
}

@media (max-width: 1410px) {
  .signuptext {
    margin-left: 10px;
    margin-right: 10px;
  }

  .verticalAlign {
    margin-left: 10px;
  }
}

@media (max-width: 1350px) {
  .loginARE {
    margin-left: 2.4%;
  }

  .verticalAlign {
    margin-left: 16px;
  }

  .signuptext {
    margin-left: 12px;
    margin-right: 28px;
  }
}

@media (max-width: 1240px) {
  .fiftyArea {
    width: 53%;
  }
}

@media (max-width: 1200px) {
  .titl {
    margin-left: 17.6%;
    font-size: 30.18px !important;
    line-height: 35px !important;
  }

  .verticalAlign {
    border: 2px solid #ffffff;
  }
}

@media (max-width: 1170px) {
  .login {
    font-size: 25.18px;
  }

  .signuptext {
    font-size: 25.18px;
  }
}

@media (max-width: 992px) {
  .titl {
    margin-left: 2%;
  }

  .loginARE {
    display: none;
  }
  .stillOff {
    display: block !important;
  }
  .loginARE1 {
    display: flex;
    align-items: center;
    margin-left: 5.4%;
  }

  .fiftyArea {
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .login {
    font-size: 22.18px;
    line-height: 26px;
  }

  .signuptext {
    font-size: 22.18px;
    line-height: 26px;
  }
}

@media (max-width: 640px) {
  .inputtext {
    height: 41px;
    font-size: 17.4713px;
  }

  .searcharaea {
    width: 270px;
  }

  .searchIcon {
    font-size: 28px;
  }

  .mainNav {
    height: 80px;
  }
}

@media (max-width: 540px) {
  .titl {
    font-size: 21px !important;
    margin-bottom: 0;
    margin-left: 0;
  }

  .inputtext {
    padding-left: 10px;
  }

  .inputtext {
    font-size: 14px;
  }

  .searchIcon {
    font-size: 24px;
  }

  .searcharaea {
    width: 210px;
  }
  .centermain {
    display: flex;
    justify-content: center !important;
    width: calc(100% - 40px) !important;
    margin: 0px !important;
  }
}

@media (max-width: 420px) {
  .searcharaea {
    width: 185px;
  }
}
