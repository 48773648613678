.order {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: -37px;
    padding-bottom: 50px;
}

.orderInner {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}


.topButons {
    display: flex;
}


.firstbutton {
    font-family: 'Hind';
    font-style: normal;
    font-weight: 400;
    font-size: 14.149px;
    line-height: 23px;
    color: #009FF9;
    width: 144px;
    height: 21px;
    background: #FFFFFF;
    border: 1.16334px solid #BCBBBB;
    text-align: center;
    margin-top: 25px;
}


.secondbutton {
    width: 160px;
    height: 21px;
    background: #F0F0F0;
    border: 1.16334px solid #000000;
    margin-left: 13px;
    margin-top: 25px;
    font-family: 'Asap', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14.149px;
    line-height: 23px;
    color: #009FF9;
    display: flex;
    align-items: center;
    border-radius: 5px;
    justify-content: center;
    cursor: pointer;
}


.lowerTable {
    margin-top: 35px;
    width: 100%;
    margin-bottom: 50px;
}

.topRow {
    font-family: 'Hind';
    font-style: normal;
    font-weight: 600;
    font-size: 13.7972px;
    line-height: 22px;
    color: #000000;
    max-width: 63px;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

.tabData {
    font-family: 'Hind';
    font-style: normal;
    font-weight: 400;
    font-size: 15.6786px;
    line-height: 25px;
    color: #17161F;

}


.textSaecr {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20.0724px;
    line-height: 30px;
    color: #000000;
}

.searchArea {
    display: flex;
    margin-top: 35px;
}


.serInput{
    width: 250px;
    height: 30px;
    padding-left: 10px;
    padding-right: 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16.0724px;
    line-height: 25px;
    color: #000000;
    margin-left: 15px;
    border-radius: 5px;
}


.confi {
    font-family: 'Hind';
    font-style: normal;
    font-weight: 400;
    font-size: 14.149px;
    line-height: 23px;
    color: #6D7488;
    width: 73.29px;
    height: 20.94px;
    text-align: center;
    background: #F0F0F0;
    border: 1.16334px solid #000000;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
}


.confiblue {
    font-family: 'Hind';
    font-style: normal;
    font-weight: 400;
    font-size: 14.149px;
    line-height: 23px;
    color: #009FF9;
    width: 73.29px;
    height: 20.94px;
    background: #F0F0F0;
    border: 1.16334px solid #000000;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}


.detl {
    font-family: 'Hind';
    font-style: normal;
    font-weight: 400;
    font-size: 14.149px;
    line-height: 23px;
    color: #6D7488;
    width: 66.31px;
    height: 20.94px;
    text-align: center;
    background: #F0F0F0;
    border: 1.16334px solid #000000;
}


@media (max-width:600px) {

    .topButons {
        flex-direction: column;
        align-items: center;
    }

}

@media (max-width:992px) {

    .topButons{
        flex-wrap: wrap;
    }
    
.orderInner{
    width: 97%;
}


}

@media (max-width:480px) {

    .searchArea{
        flex-direction: column;
        align-items: center;
    }
    
}