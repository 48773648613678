.home {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    background: linear-gradient(#141e30, #243b55);
    display: flex;
    min-height: 100vh;
}

.logArea {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    padding: 100px 0px;
}


.loginBox {
    min-width: 400px;
    padding: 40px;
    background: linear-gradient(90deg, #FFF000 0%, #F47721 100%);
    box-sizing: border-box;
    box-shadow: 0 15px 25px rgb(0 0 0 / 60%);
    border-radius: 10px;
}

.heading {
    margin: 0 0 30px;
    padding: 0;
    color: #fff;
    text-align: center;
    font-size: 15px;
    font-weight: 700;
}

.putTlt {
    font-size: 10px;
    font-weight: 400;
    color: white;
}

.numInput {
    width: 177px;
    height: 30px;
    background: white;
    color: black;
    outline: none;
    border: none;
    border-radius: 8px;
    margin-left: 5px;
    padding: 0 8px;
}

.crear {
    display: flex;
    justify-content: center;
}

.descripHeading {
    color: #fff;
}

.crearbtn {
    margin-left: 10px;
    color: black;
    font-size: 14px;
    padding: 10px;
    border: none;
    background: #fff;
    margin-top: 10px;
    border-radius: 10px;
}


.informationSection {
    display: flex;
    flex-direction: column;
}

.subaHead {
    color: #fff;
    font-size: 20px;
}

.inputArea {
    display: flex;
    margin-top: 8px;
    align-items: center;
    width: 100%;
}

.subButton {
    padding: 1px 6px;
    background: white;
    border-radius: 5px;
    margin-top: 12px;
    font-size: 12px;
    border: none;
    padding: 5px;
}

.titu {
    color: #fff;
}

.tituIut {
    width: 80%;
    outline: none;
    border-radius: 5px;
    border: none;
}


.tituInput {
    background: #fff;
    padding: 5px 10px;
    margin-left: 7px;
    border-radius: 7px;
    border: none;
    outline: none;
}


.descriptionArea {
    display: flex;
    margin-top: 12px;
}

.textWidth {
    width: 500px;
    outline: none;
    border: none;
    border-radius: 20px;
    padding: 10px;
}

.priceArea {
    display: flex;
    align-items: center;
    margin-top: 12px;
}

.pric1 {
    width: 177px;
    padding: 2px 5px;
    border-radius: 5px;
    outline: none;
    border: none;
    margin-left: 8px;
    margin-top: 5px;
}

.pricHead {
    color: #fff;
}

.upbtn {
    padding: 5px;
    border: none;
    border-radius: 5px;
    margin-top: 10px;
    max-width: 120px;
}


.submitBtn {
    border: none;
    padding: 7px;
    border-radius: 4px;
    max-width: 120px;
}

.upBtn {
    color: #fff;
}




@media (max-width:992px) {

    .textWidth {
        width: 400px;
    }

}

@media (max-width:820px) {

    .textWidth {
        width: 350px;
    }

}


@media (max-width:767px) {

    .home {
        flex-direction: column;
    }

    .logArea {
        width: 100%;
    }

    .logArea {
        padding: 0;
    }


}



@media (max-width:550px) {


.priceArea{
    flex-direction: column;
    align-items: center;
}


    .crear {
        flex-direction: column;
        align-items: center;
    }

    .loginBox {
        min-width: unset;
        width: 80%;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .submitt {
        margin-top: 20px;
    }

    .subButton {
        margin-top: 20px;
    }


    .numInput {
        margin-top: 10px;
    }

    .textWidth {
        width: 250px;
    }


    .descriptionArea{
        flex-direction: column;
    }

}