.recover {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 30px;

}

.outerRecover {
    display: flex;
    flex-direction: column;
    padding-top: 100px;
    width: 100%;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    border: 3px solid #FFCD32;
    border-radius: 22px;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    height: 821px;
    margin-bottom: 30px;
}

.recoveryHeading {
    font-family: 'Asap', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 61.4639px;
    line-height: 98px;
    color: #000000;
}


.recoverdetail {
    margin-top: 83px;
    max-width: 557px;
    font-family: 'Hind';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 144.1%;
    text-align: center;
    color: #000000;
}


.catsArea {
    display: flex;
}

.cat1 {
    width: 184px;
    height: 172px;
    margin-top: 82px;
}

.cat2 {
    width: 205px;
    height: 193px;
    margin-top: 70px;
    margin-left: 31px;
}


.cat3 {
    width: 283px;
    height: 161px;
    margin-top: 103px;
    margin-left: 26px;
}

.cat4 {
    width: 264px;
    height: 225px;
    margin-top: 45px;
    margin-left: 44px;
}


@media (max-width:1130px) {

    .outerRecover {
        padding-top: 30px;
        height: 634px;
    }

    .recover{
        padding-top: 30px;
    }


    .cat1 {
        width: 132px;
        height: 124px;
        margin-top: 82px;
    }

    .cat2 {
        width: 139px;
        height: 125px;
        margin-top: 70px;
        margin-left: 15px;
    }


    .cat3 {
        width: 210px;
        height: 109px;
        margin-top: 103px;
        margin-left: 20px;
    }

    .cat4 {
        width: 174px;
        height: 155px;
        margin-top: 45px;
        margin-left: 20px;
    }
}


@media (max-width:767px) {


    .recoveryHeading{
        font-size: 34.4639px;
    line-height: 40px;
    }

    .recoverdetail{
        margin-top: 25px;
        font-size: 20px;
        line-height: 144.1%;
    }
    
        .cat1{
display: none;
    }

    .cat4{
        display: none;
    }

}


@media (max-width:450px) {


    .outerRecover{
        height: 441px;
        border: 2px solid #FFCD32;
    }
    
    .cat2{
        width: 120px;
        height: 110px;
        margin-top: 50px;
        margin-left: 15px;
    }

.cat3{
    width: 134px;
    height: 90px;
    margin-top: 77px;
    margin-left: 20px;
}

}